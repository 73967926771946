<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields
        },

        computed: {
            author() {
                return this.$store.getters['author/getAuthor'];
            },
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.authors, 'Authors'),
                        disabled: false,
                        to: '/authors',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.create, 'Create'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted() {
            this.clear();
        },

        methods: {
            clear() {
                this.$store.dispatch('author/clear');
            },
            async submit() {
                this.$store.dispatch('common/changeErrorMessages', [], {root: true});
                let data = fillForm(this.fillable, this.author);
                await this.$store.dispatch('author/store', data);
            },
        },
    }
</script>