export const index = (state, categories) => {
    state.categories = categories;
};

export const show = (state, category) => {
    state.category = category;
};

export const clear = state => {
    state.category = {
        parent: 0,
    };
};

export const clearAll = state => {
    state.categories = [];
};