<template>
    <form v-if="banner">
        <v-text-field
                v-model="banner.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>
        <v-select
            @change="updateRatio"
            v-if="types"
            v-model="banner.type"
            :items="types"
            :label="checkTranslation(user.profile.translations.type, 'Type')"
            :error-messages="error_messages.type"
            clearable
            item-text="type"
            item-value="type"
        ></v-select>
        <upload-image-cropper
            :imgWidth="ratio[0]"
            :imgHeight="ratio[1]"
            :model="banner"
            :image="banner.image"
            @uploadImage="setImage($event)"
            @removeImage="removeImage()"
            :key="componentKey"
        ></upload-image-cropper>
        
        <v-text-field
            v-model="banner.url"
            :error-messages="error_messages.url"
            :label="checkTranslation(user.profile.translations.url, 'Link')"
            required
        ></v-text-field>
        <v-checkbox
            :label="checkTranslation(user.profile.translations.target_blank, 'Open in new tab')"
            v-model="banner.target_blank"
            color="primary"
        ></v-checkbox>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="banner.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>

</template>

<script>
    export default {
        data() {
            return {
                componentKey: 0,
            }
        },
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            banner() {
                return this.$store.getters['banner/getBanner'];
            },
            types() {
                return this.$store.getters['banner/getTypes'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
            ratio: function () {
                return this.updateRatio();
            }
        },
        methods: {
            forceRerender() {
                this.componentKey += 1;
            },
            updateRatio() {
                this.forceRerender();
                console.log(this.componentKey);
                return this.banner.type ? this.banner.type.split('x') : [];
            },
            setImage(image) {
                this.banner.image = image.file;
            },
            removeImage(image) {
                this.banner.image = null;
                this.banner.remove_image = true;
            },
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('banner/clear');
            },
        },

    }
</script>
