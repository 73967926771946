export default {
    namespaced: true,

    state: {
        loading: false,
        loadingExport: false,
        error_messages: [],
        pagination: {},
        total: 0,
        locale: 'en',
        dialog: false,
    },

    getters: {
        getLoading: state => {
            return state.loading;
        },

        getLoadingExport: state => {
            return state.loadingExport;
        },

        getErrorMessages: state => {
            return state.error_messages;
        },

        getPagination: state => {
            return state.pagination;
        },

        getTotal: state => {
            return state.total;
        },

        getLocale: state => {
            return state.locale;
        },

        getDialog: state => {
            return state.dialog;
        },
    },

    mutations: {
        changeLoading: (state, callback) => {
            state.loading = callback;
        },

        changeLoadingExport: (state, callback) => {
            state.loadingExport = callback;
        },

        changeErrorMessages: (state, callback) => {
            state.error_messages = callback;
        },

        changePagination: (state, callback) => {
            state.pagination = callback;
        },

        changeTotal: (state, callback) => {
            state.total = callback;
        },

        changeLocale: (state, callback) => {
            state.locale = callback == undefined ? 'en' : callback;
        },

        changeDialog: (state, callback) => {
            state.dialog = callback;
        },

    },

    actions: {
        changeLoading: (context, data) => {
            context.commit('changeLoading', data);
        },

        changeLoadingExport: (context, data) => {
            context.commit('changeLoadingExport', data);
        },

        changeErrorMessages: (context, data) => {
            context.commit('changeErrorMessages', data);
        },

        changePagination: (context, data) => {
            context.commit('changePagination', data);
        },

        changeTotal: (context, data) => {
            context.commit('changeTotal', data);
        },

        changeLocale: (context, data) => {
            context.commit('changeLocale', data);
        },

        changeDialog: (context, data) => {
            context.commit('changeDialog', data);
        },
    },
};
