<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <v-card-title>

                        <v-flex xs6 sm4 d-flex v-if="showSelect">
                            <v-select
                                    @change="multipleChange()"
                                    v-model="multiple"
                                    :items="dropdown_button"
                                    :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                    :disabled="disabled"
                                    item-text="title"
                                    item-value="key"
                                    solo
                            ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>

                    </v-card-title>

                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                        <template v-slot:item.is_visible="{ item }">
                            <v-icon>{{ isVisible(item.is_visible) }}</v-icon>
                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom v-if="checkGuard('language-edit')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="primary" :to="'/languages/' + item.id + '/edit'" v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="error" @click="destroy(item)" v-if="checkGuard('language-delete')" v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.delete, 'Delete') }}</span>
                            </v-tooltip>
                        </template>

                    </v-data-table>
                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('language-create')">
                <v-btn :color="'primary'" dark fab fixed bottom right to="/languages/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';

    export default {

        data() {
            return {
                headers: [
                    {text: 'ID', align: 'left', sortable: true, value: 'id'},
                    {text: 'TITLE', align: 'left', sortable: true, value: 'title'},
                    {text: 'LOCALE', align: 'left', sortable: true, value: 'locale'},
                    {text: 'IS_VISIBLE', align: 'left', sortable: true, value: 'is_visible'},
                    {text: 'CREATED AT',sortable: false,  value: 'created_at'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ],
                model: 'Language',
                showSelect: false,
            }
        },

        mixins: [MultipleSelect,],

        computed: {
            items(){
                return this.$store.getters['language/getLanguages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.languages, 'Languages'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted(){
            this.translate();
        },

        methods: {
            async getLanguages() {
                await this.$store.dispatch('language/index', this.pagination);
            },
            async destroy(row){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('language/destroy', { ...row, vuetify });
            },
            translate(){
               this.headers[1].text = checkTranslation(this.user.profile.translations.title.toUpperCase(), 'TITLE');
               this.headers[2].text = checkTranslation(this.user.profile.translations.locale.toUpperCase(), 'LOCALE');
               this.headers[3].text = checkTranslation(this.user.profile.translations.is_visible.toUpperCase(), 'IS_VISIBLE');
               this.headers[4].text = checkTranslation(this.user.profile.translations.created_at.toUpperCase(), 'CREATED_AT');
               this.headers[5].text = checkTranslation(this.user.profile.translations.action.toUpperCase(), 'ACTION');
            },
        },

        watch: {
            pagination: {
                handler () {
                    this.getLanguages();
                },
                deep: true
            },
        },

        async beforeDestroy(){
            await this.$store.dispatch('language/clearAll');
        },
    }
</script>
