<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                
                    <v-card class="pa-4">

                        <form-fields @submitForm="submit($event)"></form-fields>

                    </v-card>

            </v-flex>

            <v-fab-transition v-if="checkGuard('banner-create')">
                <v-btn color="primary" dark fab fixed bottom right to="/banners/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>

        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
            }
        },

        mixins: [CheckGuard,],
        components: {
            FormFields,
        },
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            banner() {
                return this.$store.getters['banner/getBanner'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.banners, 'Banners'),
                        disabled: false,
                        to: '/banners',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },
        async mounted() {
            this.clear();
            await this.getBanner();
        },
        methods: {
            async getBanner() {
                await this.$store.dispatch('banner/show', this.$route.params.slug);
            },
            clear() {
                this.$store.dispatch('banner/clear');
            },
            async submit() {
                this.prepareSubmit();
                let data = fillForm(this.fillable, this.banner, 'PUT');
                await this.$store.dispatch('banner/update', [data, this.$route.params.slug],);
            },
            prepareSubmit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
            },
        },
    }
</script>
