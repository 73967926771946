<template>
    <form>
        <v-text-field
                v-model="permission.name"
                :error-messages="error_messages.name"
                :label="checkTranslation(user.profile.translations.name, 'Name')"
                required
        ></v-text-field>

        <v-text-field
                v-model="permission.guard_name"
                :error-messages="error_messages.guard_name"
                :label="checkTranslation(user.profile.translations.guard_name, 'Guard name')"
                required
        ></v-text-field>

        <v-flex xs12 sm12 d-flex>
            <v-select
                    :items="permission_groups"
                    :label="checkTranslation(user.profile.translations.permission_group, 'Permission group')"
                    v-model="permission.permission_group_id"
                    :error-messages="error_messages.permission_group_id"
                    item-text="name"
                    item-value="id"
            ></v-select>
        </v-flex>

        <v-checkbox
                label="Is visible"
                v-model="permission.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">submit</v-btn>
        <v-btn @click="clear()" color="secondary" tile>clear</v-btn>
    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            permission() {
                return this.$store.getters['permission/getPermission'];
            },
            permission_groups() {
                return this.$store.getters['permission_group/getPermissionGroups'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear(){
                this.$store.dispatch('permission/clear');
            },
        },
    }
</script>