var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"mb-4"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_vm._v(" "),_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[(_vm.showSelect)?_c('v-flex',{attrs:{"xs6":"","sm4":"","d-flex":""}},[_c('v-select',{attrs:{"items":_vm.dropdown_button,"label":_vm.checkTranslation(_vm.user.profile.translations.choose, 'Choose'),"disabled":_vm.disabled,"item-text":"title","item-value":"key","solo":""},on:{"change":function($event){return _vm.multipleChange()}},model:{value:(_vm.multiple),callback:function ($$v) {_vm.multiple=$$v},expression:"multiple"}})],1):_vm._e(),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.total_items,"loading":_vm.loading,"items-per-page":50,"footer-props":{
                          itemsPerPageOptions: [50,25,10],
                          itemsPerPageText: _vm.checkTranslation(_vm.user.profile.translations.rows_per_page, 'Rows per page'),
                        },"show-select":_vm.showSelect},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.is_visible",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.isVisible(item.is_visible)))])]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(_vm.checkGuard('permission-group-edit'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","icon":"","color":"primary","to":'/permission-groups/' + item.id + '/edit'}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.checkTranslation(_vm.user.profile.translations.edit, 'Edit')))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(_vm.checkGuard('permission-group-delete'))?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","icon":"","color":"error"},on:{"click":function($event){return _vm.destroy(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.checkTranslation(_vm.user.profile.translations.delete, 'Delete')))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_vm._v(" "),(_vm.checkGuard('permission-group-create'))?_c('v-fab-transition',[_c('v-btn',{attrs:{"color":'primary',"dark":"","fab":"","fixed":"","bottom":"","right":"","to":"/permission-groups/create"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }