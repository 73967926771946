export default {
    namespaced: true,
    state: {
        /** show nav bars **/
        showLeftBar: false,
        showTopBar: false,
        showRightBar: false,
    },
    getters: {
        /** show nav bars **/
        getShowLeftBar: state => {
            return state.showLeftBar;
        },
        getShowTopBar: state => {
            return state.showTopBar;
        },
        getShowRightBar: state => {
            return state.showRightBar;
        },
    },
    mutations: {
        /** change nav bars **/
        changeShowLeftBar: state => {
            state.showLeftBar = !state.showLeftBar;
        },
        setShowLeftBar: (state, data) => {
            state.showLeftBar = data;
        },
        setShowTopBar: (state, data) => {
            state.showTopBar = data;
        },
        setShowRightBar: (state, data) => {
            state.showRightBar = data;
        },
        changeShowRightBar: state => {
            state.showRightBar = !state.showRightBar;
        },
    },
    actions: {
        /** show nav bars **/
        changeShowLeftBar: context => {
            context.commit('changeShowLeftBar');
        },
        setShowLeftBar: (context, data) => {
            context.commit('setShowLeftBar', data);
        },
        setShowTopBar: (context, data) => {
            context.commit('setShowTopBar', data);
        },
        setShowRightBar: (context, data) => {
            context.commit('setShowRightBar', data);
        },
        changeShowRightBar: context => {
            context.commit('changeShowRightBar');
        },
    },
};
