<template>

    <v-container fluid fill-height>
        <v-layout>

            <v-flex>

                <v-card class="mb-4">

                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>

                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>

            </v-flex>

        </v-layout>

    </v-container>

</template>

<script>
    import moment from 'moment';
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {

        data() {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        mixins: [CheckGuard, ],

        computed: {
            userProfile(){
                return this.$store.getters['user/getUserProfile'];
            },
            user(){
                return this.$store.getters['user/getUser'];
            },
            currentTime(){
                return moment().format('YYYY-MM-DD H:mm:ss');
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.userProfile.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.userProfile.profile.translations.users, 'Users'),
                        disabled: false,
                        to: '/users',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.userProfile.profile.translations.create, 'Create'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted(){
            this.clear();
            await this.getRoles();
            await this.getLanguages();
        },

        methods: {
            async getRoles(){
                await this.$store.dispatch('role/index', {
                    page: 1,
                    itemsPerPage: 50,
                    sortBy: 'id',
                    sortDesc: [1],
                    totalItems: 0,
                    lists: true,
                    isVisible: true,
                });
            },
            async getLanguages(){
                await this.$store.dispatch('language/index', {
                    page: 1,
                    itemsPerPage: 50,
                    sortBy: "id",
                    sortDesc: [1],
                    totalItems: 0,
                    lists: true,
                    isVisible: true,
                });
            },
            async submit(){
                this.prepareSubmit();
                let data = fillForm(this.fillable, this.user);
                await this.$store.dispatch('user/store', data);
            },
            clear(){
                this.$store.dispatch('user/clear');
            },
            prepareSubmit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});

                if(this.user.email_verified_at) this.user.email_verified_at = this.currentTime;

                if(!this.checkGuard('language-create')) this.user.language_id = 1;

                const role_ids = this.user.roles !== undefined ? this.user.roles.map(a => a.id) : [];
                this.user.role_ids = role_ids.length > 0 ? role_ids : '';
            },
        },
    }
</script>
