export const index = (state, categories) => {
    state.categories = categories;
};

export const show = (state, category) => {
    state.category = category;
};

export const index_sort = (state, categories_sort) => {
    state.categories_sort = categories_sort;
};

export const clear = state => {
    state.category = {
        parent: 0,
        remove_image: false,
    };
};

export const clearAll = state => {
    state.categories = [];
};