import swal from 'sweetalert2';
const router = '../../../router';
/**
 * method used to do multiple action to the Backend and store in Vuex
 *
 * @param {object} context
 * @param {array} callback - (array[0] - multiple item, array[1] - selected item, array[2] - vuetify, array[3] - model, callback[4] - $route.params.id)
 */
export const update = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    if (callback[0] !== 'choose') {
        await swal.fire({
            title: user.profile.translations.are_you_sure,
            text: user.profile.translations.you_wont_be_able_to_revert_this,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: callback[2].theme.primary,
            cancelButtonColor: callback[2].theme.secondary,
            confirmButtonText: checkTranslation(user.profile.translations.submit, 'Submit!'),
            cancelButtonText: checkTranslation(user.profile.translations.cancel, 'Cancel'),
        }).then(async (result) => {
            if (result.value) {
                let ids = callback[1].map(post => {
                    return post.id;
                });

                switch (callback[0]) {
                    case 'delete' :
                        await axios.delete('api/multiples', {data: {ids: ids, model: callback[3]}})
                            .then(res => {
                                context.dispatch(camelCaseToUnderscore(callback[3]) + '/index', setData(callback[4]), {root: true});
                                swal.fire(
                                    checkTranslation(user.profile.translations.deleted, 'Deleted'),
                                    checkTranslation(user.profile.translations.your_file_has_been_deleted, 'Your file has been deleted.'),
                                    'success',
                                );
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        break;

                    case 'publish':
                    case 'unpublish':
                    case 'block':
                    case 'unblock':
                        await axios.post('api/multiples', {ids: ids, model: callback[3], value: (callback[0] === 'publish' || callback[0] === 'block') ?  true : false})
                            .then(res => {
                                context.dispatch(camelCaseToUnderscore(callback[3]) + '/index', setData(callback[4]), {root: true});
                                swal.fire(
                                    checkTranslation(user.profile.translations.success, 'Success'),
                                    checkTranslation(user.profile.translations.your_files_has_been_published, 'Your files has been published.'),
                                    'success',
                                );
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        break;
                }

            }
        });
    }
};

export const setData = (id=false) => {
    if(id !== false && id !== undefined){
        return [
            id, {
                page: 1,
                itemsPerPage: 50,
                sortBy: "id",
                sortDesc: [0],
                totalItems: 0,
            }
        ];
    }else{
        return {
            page: 1,
            itemsPerPage: 50,
            sortBy: "id",
            sortDesc: [0],
            totalItems: 0,
        };
    }
};