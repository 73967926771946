export const index = (state, carts) => {
    state.carts = carts;
};

export const show = (state, cart) => {
    state.cart = cart;
};

export const clear = state => {
    state.cart = {};
};

export const clearAll = state => {
    state.carts = [];
};