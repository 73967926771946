<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <v-card-title>

                        <v-flex xs4 d-flex v-if="showSelect">
                            <v-select
                                    @change="multipleChange()"
                                    v-model="multiple"
                                    :items="dropdown_button"
                                    :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                    :disabled="disabled"
                                    item-text="title"
                                    item-value="key"
                                    solo
                            ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>



                    </v-card-title>

                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                    </v-data-table>
                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';

    export default {
        data() {
            return {
                model: 'Newsletter',
                search: '',
                showSelect: false,
            };
        },

        mixins: [MultipleSelect,],

        computed: {
            items() {
                return this.$store.getters['newsletter/getNewsletters'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.newsletter, 'Newsletter'),
                        disabled: true,
                    },
                ];
            },
            headers() {
                return [
                    {text: 'ID', align: 'left', value: 'id'},
                    {text: 'EMAIL', align: 'left', value: 'email'},
                ];
            }
        },

        mounted() {
            this.translate();
        },

        methods: {
            async getNewsletters() {
                this.pagination.newsletter = this.search === '' ? undefined : this.search;
                await this.$store.dispatch('newsletter/index', this.pagination);
            },
            translate() {
                this.headers[1].text = this.user.profile.translations.email.toUpperCase();
            },
            async searchNewsletter() {
                this.pagination.newsletter = this.search;
                this.pagination.page = 1;
                await this.getNewsletters();
            },
            async resetSearch() {
                this.search = '';
                this.pagination.newsletter = undefined;
                this.pagination.page = 1;
                await this.getNewsletters();
            }
        },
        watch: {
            pagination: {
                handler() {
                    this.getNewsletters();
                },
                deep: true
            },
        },
    }
</script>