<template>
    <form v-if="page" enctype="multipart/form-data">
        <v-text-field
                v-model="page.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>
        <textarea-ckeditor
            :label="checkTranslation(user.profile.translations.content, 'Content')"
            :value="page.content"
            :required="true"
            @changeValue="page.content = $event"
        ></textarea-ckeditor>
        <upload-image-cropper
            :imgWidth="640"
            :imgHeight="480"
            :model="page"
            :image="page.image"
            @uploadImage="setImage($event)"
            @removeImage="removeImage()"
        ></upload-image-cropper>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="page.is_visible"
                color="primary"
        ></v-checkbox>
        <v-btn
            @click.prevent="submit()"
            color="primary"
            tile
            class="mr-3">
                {{checkTranslation(user.profile.translations.submit, 'Submit') }}
        </v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            page() {
                return this.$store.getters['page/getPage'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('page/clear');
            },
            setImage(image) {
                this.page.image = image.file;
            },
            removeImage(image) {
                this.page.image = null;
                this.page.remove_image = true;
            },
        },
    }
</script>
