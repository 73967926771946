<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md8 lg6>

                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Återställ lösenord</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>

                        <v-form>

                            <v-text-field label="lösenord"
                                          required
                                          type="password"
                                          v-model="auth.password"
                                          :error="error_messages.password == false"
                                          :error-messages="error_messages.password"
                                          @keyup.enter="submit()">
                            </v-text-field>

                            <v-text-field label="bekräfta lösenord"
                                          required
                                          type="password"
                                          v-model="auth.password_confirmation"
                                          :error="error_messages.password_confirmation == false"
                                          :error-messages="error_messages.password_confirmation"
                                          @keyup.enter="submit()">
                            </v-text-field>

                        </v-form>

                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click.prevent="submit()">Återställ lösenord</v-btn>
                    </v-card-actions>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                fillable: ['email', 'password', 'password_confirmation', 'token'],
            };
        },
        computed: {
            auth(){
                return this.$store.getters['auth/getAuth'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        mounted() {
            this.auth.token = this.$route.params.token;
        },
        methods: {
            async submit(){
                let data = fillForm(this.fillable, this.auth, 'PUT');
                await this.$store.dispatch('auth/reset_password', data);
            }
        }
    }
</script>