export const index = (state, deliveries) => {
    state.deliveries = deliveries; 
};

export const show = (state, delivery) => {
    state.delivery = delivery;
};

export const clear = state => {
    state.delivery = {};
};

export const clearAll = state => {
    state.deliveries = [];
};