export const index = (state, authors) => {
    state.authors = authors;
};

export const show = (state, author) => {
    state.author = author;
};

export const clear = state => {
    state.author = {
        remove_image: false,
    };
};

export const clearAll = state => {
    state.authors = [];
};