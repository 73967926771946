<template>
    <form v-if="book">
        <v-select
                v-if="categories"
                v-model="book.category_id"
                :items="categories"
                :label="checkTranslation(user.profile.translations.category, 'Category')"
                :error-messages="error_messages.category_id"
                clearable
                item-text="title"
                item-value="id"
        ></v-select>
        <v-select
                v-if="statuses"
                v-model="book.status_id"
                :items="statuses"
                :label="checkTranslation(user.profile.translations.status, 'Status')"
                :error-messages="error_messages.status_id"
                select
                clearable
                item-text="title"
                item-value="id"
        ></v-select>
        <v-text-field
                v-model="book.video_url"
                :error-messages="error_messages.video_url"
                :label="checkTranslation(user.profile.translations.video_url, 'video_url')"
        ></v-text-field>
        <v-text-field
                v-model="book.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
        ></v-text-field>
        <v-text-field
                v-model="book.text"
                :error-messages="error_messages.text"
                :label="checkTranslation(user.profile.translations.text, 'Text')"
                required
        ></v-text-field>
        <v-text-field
                v-model="book.code"
                :error-messages="error_messages.code"
                :label="checkTranslation(user.profile.translations.code, 'Code')"
                required
        ></v-text-field>
        <v-text-field
                v-model="book.url_id"
                :error-messages="error_messages.code"
                :label="checkTranslation(user.profile.translations.url_id_book, 'Url ID')"
        ></v-text-field>
        <v-select
                v-if="authors"
                v-model="book.authors"
                :items="authors"
                :label="checkTranslation(user.profile.translations.authors, 'Authors')"
                :error="error_messages.authors_ids == false"
                :error-messages="error_messages.authors_ids"
                item-text="name"
                item-value="id"
                multiple
                chips
        ></v-select>
        <v-select
            v-if="tags"
            v-model="book.tags"
            :items="tags"
            :label="checkTranslation(user.profile.translations.tags, 'Tags')"
            :error="error_messages.tags_ids == false"
            :error-messages="error_messages.tags_ids"
            item-text="title"
            item-value="id"
            multiple
            chips
        ></v-select>
        <v-text-field
                v-model="book.year"
                :error-messages="error_messages.year"
                :label="checkTranslation(user.profile.translations.year, 'Year')"
                required
        ></v-text-field>

        <v-text-field
                v-model="book.equipment"
                :error-messages="error_messages.equipment"
                :label="checkTranslation(user.profile.translations.equipment, 'Equipment')"
                required
        ></v-text-field>
        <v-text-field
                v-model="book.design"
                :error-messages="error_messages.design"
                :label="checkTranslation(user.profile.translations.design, 'Design')"
                required
        ></v-text-field>

        <v-textarea
                v-model="book.summary"
                :error-messages="error_messages.summary"
                :label="checkTranslation(user.profile.translations.summary, 'Summary')"
        ></v-textarea>

        <textarea-ckeditor
                :label="checkTranslation(user.profile.translations.content, 'Content')"
                :value="book.content"
                :required="false"
                @changeValue="book.content = $event"
        ></textarea-ckeditor>

        <upload-image-cropper
                :imgWidth="287"
                :imgHeight="406"
                :model="book"
                :image="book.image"
                @uploadImage="setImage($event)"
                @removeImage="removeImage()"
        ></upload-image-cropper>

        <span v-if="book.file">
              <v-icon @click="openLink(book.file)">mdi-clipboard-file</v-icon>
              Pdf Odlomak
            <v-icon @click="destroy(book)">mdi-minus</v-icon>
        </span>
        <v-file-input v-else
                      v-model="book.file"
                      :error-messages="error_messages.file"
                      :label="checkTranslation(user.profile.translations.file, 'File')"
        ></v-file-input>
        <v-text-field
                v-model="book.price"
                :error-messages="error_messages.price"
                :label="checkTranslation(user.profile.translations.price, 'Price')"
                type="numeric"
                required
        ></v-text-field>
        <v-text-field
                v-model="book.discount"
                :error-messages="error_messages.discount"
                :label="checkTranslation(user.profile.translations.discount, 'Discount')"
                type="numeric"
                :required="false"
                placeholder="%"
        ></v-text-field>
        <v-text-field
            v-model="book.order"
            :error-messages="error_messages.order"
            :label="checkTranslation(user.profile.translations.order, 'Order')"
            type="numeric"
            required
        ></v-text-field>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="book.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>

</template>

<script>
    import router from '../../router';
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            book() {
                return this.$store.getters['book/getBook'];
            },
            authors() {
                return this.$store.getters['author/getAuthors'];
            },
            tags() {
                return this.$store.getters['tag/getTags'];
            },
            categories() {
                return this.$store.getters['category/getCategories'];
            },
            statuses() {
                return this.$store.getters['status/getStatuses'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            setImage(image) {
                this.book.image = image.file;
            },
            removeImage(image) {
                this.book.image = null;
                this.book.remove_image = true;
            },
            openLink(link){
                window.open(link,'_blank');
            },
            async destroy(row){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('book/removePdf', { ...row, vuetify });
                router.push('/books');
            },
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('book/clear');
            },
        },
    }
</script>
