export default {
    data(){
        return {
            pagination: {
                descending: true,
            },
            disabled: true,
            multiple: null,
            selected: [],
            dropdown_button: [],
        };
    },
    computed: {
        user(){
            return this.$store.getters['user/getUserProfile'];
        },
        loading(){
            return this.$store.getters['common/getLoading'];
        },
        total_items(){
            return this.$store.getters['common/getTotal'];
        },
    },
    mounted(){
        this.getDropDownButton();
    },
    methods: {
        checkGuard(name){
            let res = false;
            let permissionList = this.$store.getters['ability/getPermissionList'];
		console.log(permissionList);
            permissionList.forEach(permission => {
                if(name === permission.name){
                    res = true;
                }
            });
            return res;
        },
        isVisible(item) {
            return item ? 'mdi-eye' : 'mdi-eye-off';
        },
        isLocked(item) {
            return item ? 'lock' : 'lock_open'
        },
        toggleAll() {
            if (this.selected.length) {
                this.selected = [];
            } else {
                this.selected = this.items.slice();
            }
        },
        toggleOne(item) {
            if (this.selected.indexOf(item) !== -1) {
                this.selected = this.selected.filter(tag => {
                    return tag.id !== item.id;
                });
            } else {
                this.selected.push(item);
            }
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        async multipleChange() {
            await this.$store.dispatch('multiple/update', [this.multiple, this.selected, this.$vuetify, this.model, this.$route.params.id]);
            this.multiple = 'choose';
            this.disabled = true;
            this.selected = [];
        },
        getDropDownButton(){
            let res = [
                {
                    key: 'choose',
                    title: checkTranslation(this.user.profile.translations.choose, 'Choose'),
                },
            ];
            if(this.checkGuard(camelCaseToDash(this.model) + '-edit')){
                res.push(
                    {
                        key: this.model === 'User' ? 'unblock' : 'publish',
                        title: this.model === 'User' ? checkTranslation(this.user.profile.translations.unblock, 'Unblock') : checkTranslation(this.user.profile.translations.visible, 'Visible'),
                    },
                    {
                        key: this.model === 'User' ? 'block' : 'unpublish',
                        title: this.model === 'User' ? checkTranslation(this.user.profile.translations.block, 'Block') : checkTranslation(this.user.profile.translations.unvisible, 'Unvisible'),
                    },
                );
            }
            if(this.checkGuard(camelCaseToDash(this.model) + '-delete')){
                res.push(
                    {
                        key: 'delete',
                        title: checkTranslation(this.user.profile.translations.delete, 'Delete'),
                    },
                );
            }
            this.dropdown_button = res;
        },
    },
    watch: {
        selected(value) {
            this.disabled = value.length === 0;
        },
    },
};
