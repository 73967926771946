export default function (Vue) {
    Vue.auth = {
        setToken(token, expiration, refresh_token){
            localStorage.setItem('skolaplus_token', token);
            localStorage.setItem('skolaplus_expiration', expiration);
            localStorage.setItem('skolaplus_refresh_token', refresh_token);
        },

        getToken(){
            let token = localStorage.getItem('skolaplus_token');
            let expiration = localStorage.getItem('skolaplus_expiration');

            if(!token || !expiration){
                return null;
            }

            return token;
        },

        destroyToken(){
            localStorage.removeItem('skolaplus_token');
            localStorage.removeItem('skolaplus_expiration');
            localStorage.removeItem('skolaplus_refresh_token');
        },

        isAuth(){
            if(this.getToken()){
                return true;
            }else{
                return false;
            }
        },
    };

    Object.defineProperties(Vue.prototype, {
        $auth: {
            get: () => {
                return Vue.auth
            }
        }
    });
}
