export const getMenus = (state) => {
    return state.menus;
};

export const getMenu = (state) => {
    return state.menu;
};

export const getLinksSort = (state) => {
    return state.links_sort;
};