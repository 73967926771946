export const getCategories = (state) => {
    return state.categories;
};

export const getCategory = (state) => {
    return state.category;
};

export const getCategoriesSort = (state) => {
    return state.categories_sort;
};

export const getTemplateTypes = (state) => {
    return state.templateTypes;
};
