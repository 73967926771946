export const index = (state, languages) => {
    state.languages = languages;
};

export const show = (state, language) => {
    state.language = language;
};

export const clear = state => {
    state.language = {
        'title': '',
        'locale': '',
        'order': '',
        'is_visible': false,
    };
};

export const clearAll = state => {
    state.languages = [];
};