import swal from 'sweetalert2';
import router from '../../../router';

/**
 * method used to Login
 *
 * @param {object} context
 */
export const login = async (context) => {
    let authUser = context.getters['getAuth'];
    let data = {
        client_id: 2,
        client_secret: '8IKM4VvvI97EhNstlauv8IKM4VvvI97EhNsskola',
        grant_type: 'password',
        username: authUser.email,
        password: authUser.password,
    };

    await axios.post('oauth/token', data)
        .then(res => {
            Vue.auth.setToken(res.data.access_token, res.data.expires_in + Date.now(), res.data.refresh_token);
            storeUser(context);
        }).catch(e => {
            context.dispatch('common/changeErrorMessages', {email: e.response.data.error}, {root:true});
        });
};

/**
 * method used to Register
 *
 * @param {object} context
 */
export const register = async (context) => {
    let authUser = context.getters['getAuth'];

    await axios.post('api/register', authUser)
        .then(res => {
            swal.fire({
                position: 'center',
                type: 'success',
                title:'Framgång',
                showConfirmButton: false,
                timer: 1500
            });
            router.push('/login');
        }).catch(e => {
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to store user item to the Backend and and store in Vuex
 *
 * @param {object} context
 */
export const storeUser = async (context) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + Vue.auth.getToken();

    await axios.get('api/profile?cms=1')
        .then(res => {
            if(res.data.data.block){
                swal.fire({
                    type: 'error',
                    title: 'Upozorenje',
                    text: 'Korisnik je blokiran!',
                });
                Vue.auth.destroyToken();
                router.push('/login');
            }else{
                let is_customer = false;
                res.data.data.roles.forEach((role) => {
                    if(role.id === 3){
                        is_customer = true;
                    }
                });

                if(is_customer){
                    Vue.auth.destroyToken();
                    router.push('/login');
                }else{
                    context.commit('user/show_profile', res.data.data, {root:true});
                    context.dispatch('nav/setShowLeftBar', true, { root: true });
                    context.dispatch('nav/setShowTopBar', true, { root: true });
                    context.dispatch('ability/changePermissionList', res.data.permissions, { root: true });
                    router.push('/');
                }
            }
        })
        .catch(e => {
            // if(e.response.status == 401){
            //     Vue.auth.destroyToken();
            //     router.push('/login');
            // }
        });
};

/**
 * method used to Refresh Token
 *
 * @param {object} context
 */
export const refresh_token = async (context) => {
    let refresh_token = localStorage.getItem('skolaplus_refresh_token') ? localStorage.getItem('skolaplus_refresh_token') : '';

    let data = {
        client_id: 2,
        client_secret: '8IKM4VvvI97EhNstlauv8IKM4VvvI97EhNsskola',
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
    };

    await axios.post('oauth/token', data)
        .then(res => {
            Vue.auth.setToken(res.data.access_token, res.data.expires_in + Date.now(), res.data.refresh_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
        }).catch(e => {
            if(e.response.status != 401){
                console.log(e);
            }
        });
};

/**
 * method used to reset password
 *
 * @param {object} context
 * @param {object} callback
 */
export const reset_password = async (context, callback) => {
    await axios.post('api/forgotten-password', callback)
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title:'Framgång',
                showConfirmButton: false,
                timer: 1500
            });
            context.dispatch('common/changeErrorMessages', [], {root:true});
        }).catch(e => {
            console.log(e.response.data.errors);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to send reset verify email password
 *
 * @param {object} dispatch
 * @param {object} commit
 * @param {object} getters
 * @param {object} rootGetters
 * @param {object} callback - user item
 */
export const resend_verify_email = async ({ dispatch, commit, getters, rootGetters }, callback) => {
    let user = rootGetters['user/getUserProfile'];
    await swal.fire({
        title: user.profile.translations.are_you_sure,
        text: user.profile.translations.you_wont_be_able_to_revert_this,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: callback.vuetify.theme.primary,
        cancelButtonColor: callback.vuetify.theme.secondary,
        confirmButtonText: checkTranslation(user.profile.translations.yes_send_it, 'Yes send it!'),
        cancelButtonText: checkTranslation(user.profile.translations.cancel, 'Cancel'),
    }).then(async (result) => {
        if (result.value) {
            await axios.put('api/users/' + callback.id + '/send-email-verification')
                .then(res => {
                    swal.fire(
                        checkTranslation(user.profile.translations.sent, 'Sent'),
                        checkTranslation(user.profile.translations.your_file_has_been_sent, 'Email verification has been sent!'),
                        'success',
                    );
                })
                .catch(e => {
                    console.log(e);
                    context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
                });
        }
    });
};

/**
 * method used to clear auth properties and error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.commit('clear');
    context.dispatch('common/changeErrorMessages', [], {root:true});
};
