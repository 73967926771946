<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields :edit="true" @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('category-create')">
                <v-btn color="primary" dark fab fixed bottom right to="/documents/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        mixins: [CheckGuard,],

        computed: {
            document() {
                return this.$store.getters['document/getDocument'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.documents, 'Documents'),
                        disabled: false,
                        to: '/documents',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted(){
            this.clear();
            await this.getGroups();
            await this.getDocument();
        },

        methods: {
            async getGroups() {
                await this.$store.dispatch('group/index', {
                    sortBy: ['id'],
                    sortDesc: [1],
                    lists: true
                });
            },
            async getDocument(){
                await this.$store.dispatch('document/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});

                let data = fillForm(this.fillable, this.document, 'PATCH');
                await this.$store.dispatch('document/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('document/clear');
            },
        },
    }
</script>
