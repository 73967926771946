<template>
    <form v-if="author">

        <v-text-field
                v-model="author.name"
                :error-messages="error_messages.name"
                :label="checkTranslation(user.profile.translations.name, 'Name')"
                required
        ></v-text-field>

        <upload-image-cropper
                :imgWidth="640"
                :imgHeight="480"
                :model="author"
                :image="author.image"
                @uploadImage="setImage($event)"
                @removeImage="removeImage()"
        ></upload-image-cropper>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="author.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {

        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            author() {
                return this.$store.getters['author/getAuthor'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            setImage(image) {
                this.author.image = image.file;
            },
            removeImage(image) {
                this.author.image = null;
                this.author.remove_image = true;
            },
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('author/clear');
            },
        },

    }
</script>