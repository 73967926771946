export const getTags = (state) => {
    return state.tags;
};

export const getTag = (state) => {
    return state.tag;
};

export const getTemplateTypes = (state) => {
    return state.templateTypes;
};
