<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('category-create')">
                <v-btn color="primary" dark fab fixed bottom right to="/category-news/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data () {
            return {
                tab: null,
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        mixins: [CheckGuard,],

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            category(){
                return this.$store.getters['category_news/getCategory'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.categories, 'Categories'),
                        disabled: false,
                        to: '/category-news',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted(){
            this.clear();
            await this.getCategories();
            await this.getCategory();
        },

        methods: {
            async getCategories(){
                await this.$store.dispatch('category_news/index', {lists: 1, sortBy: ['order'], sortDesc: [0]});
            },
            async getCategory(){
                await this.$store.dispatch('category_news/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                let data = fillForm(this.fillable, this.category, 'PUT');
                await this.$store.dispatch('category_news/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('category_news/clear');
            },
        },
    }
</script>
