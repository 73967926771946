export const index = (state, schools) => {
    state.schools = schools;
};

export const show = (state, school) => {
    state.school = school;
};

export const clear = state => {
    state.school = {};
};

export const clearAll = state => {
    state.school = [];
};
