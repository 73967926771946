export const index = (state, digitalBookCodes) => {
    state.digitalBookCodes = digitalBookCodes;
};

export const show = (state, digitalBookCode) => {
    state.digitalBookCode = digitalBookCode;
};

export const clear = state => {
    state.digitalBookCode = {};
};

export const clearAll = state => {
    state.digitalBookCodes = [];
};
