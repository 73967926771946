/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

window.domain = document.head.querySelector("[name=domain]").content + '/';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Helper function for fill forms
 */

window.fillForm = function (formArray, model, method = 'POST', locale = '') {
    let data = new FormData();
    data.append('_method', method);
    data.append('locale', locale !== '' ? locale : 'sr');

    formArray.forEach(item => {
        if (item.type === 'json') {
            model[`${item.field}`] = JSON.stringify(model[`${item.field}`]);
        } else if (item.type === 'file') {
            model[`${item.field}`] = typeof model[`${item.field}`] === 'string' ? null : model[`${item.field}`];
        }
        data.append(item.field, (model[`${item.field}`] !== undefined && model[`${item.field}`] !== '' && model[`${item.field}`] !== null) ? model[`${item.field}`] : '');
    });
    return data;
};

/**
 * Helper function to check if translation is available or return callback
 */
window.checkTranslation = function (word, callback) {
    return (word !== undefined && word !== '') ? word : callback;
};

/**
 * Helper function to CamelCase to underscore
 */
window.camelCaseToUnderscore = function (word) {
    return word.split(/(?=[A-Z])/).join('_').toString().toLowerCase();
};

/**
 * method used to convert base64 to File
 *
 * @param dataurl
 * @param filename
 * @returns {File}
 */
window.dataURLtoFile = function (dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
};

/**
 * Helper function to CamelCase to dash
 */
window.camelCaseToDash = function (word) {
    return word.split(/(?=[A-Z])/).join('-').toString().toLowerCase();
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'
//
// window.Pusher = require('pusher-js');
//
// // Pusher.logToConsole = true
// window.Echo = new Echo({
//     authEndpoint: '/broadcasting/auth',
//     broadcaster: 'pusher',
//     key: '7d8fa51c5928bf339efa',
//     cluster: 'eu',
//     encrypted: false,
//     auth: {
//         headers : { 'Authorization':'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjcxNWJiZmRhYjQzOTQ4NmNmZDMwZGUyNjlkYzMwNzZlNzU0YmU1M2JiYTBkYWIwNmU5NWIzNTQ3ZWU2YjU1ZDkyMzljMTgzZjAzZjNjMWNhIn0.eyJhdWQiOiIyIiwianRpIjoiNzE1YmJmZGFiNDM5NDg2Y2ZkMzBkZTI2OWRjMzA3NmU3NTRiZTUzYmJhMGRhYjA2ZTk1YjM1NDdlZTZiNTVkOTIzOWMxODNmMDNmM2MxY2EiLCJpYXQiOjE1Mzk1OTAyNDUsIm5iZiI6MTUzOTU5MDI0NSwiZXhwIjoyMTcwNzQyMjQ1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.V-hf5to1y6jJkNuF5kQhsrnu_Fc0czCm9PRpYVNfXy8U-7nKfGESsc4U7FPsSSsQZEdsxy_suz3flfMThctkAINWafry_3NfE4lwe8cLps-eKY5aWiKpyQIWQ2w-NePL1LHcddloeYo1tys83YEoDjr-bx6qEYacJJYtIO4USMQaKwbHZHJYgBvCi4ct7_7DaJg06AJUscxoMmtVizCzPqpxd0uNV18CM07ZADqHE_kTT8bXg5RE4_8BI8k7JHSsi1XaaazdXND0uIoL1WUOgKghPIJyoUEel_g76lULHY0klGRT5VuOpb2azXOiQ_io9FtUsDPwnF4odkPhJtdLtmXanhG-0ZKqm7KAQKM4dIXPTtpnFxbpUh5RyiKXc8vTN7l3cD6PB_v21JiBcCz6NRbAP8RaVrs66SV1bXhiLgkzqDd8pruUQBHE_-OcPbrTyGW4jz70rU9_e24A4-4Cc-ZP3o-9gW8dBB9aBJ_bqBYLOfOBAxXwnB5jVdYe-Dr-jkvLQx6yf5sxH_s6r9yK5HeiPUcuPkoj5f6WkTcUdjNFrVbOxs93KxrhhV7DtjxrjyZDE7VekjRpUeeic5IZQgJnCMwSMFNBvS9o-1HnKcOcCndDbg9Ib0MhNpRYTi71oBYABZe-WV3aZPxQ6GlQcqh4eYWokppgafgjU0H8r8k' }
//     }
// });
