import Vue from 'vue';
import VueRouter from 'vue-router';
import beforeEach from './beforeEach';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        { path: "*", component: require('../components/404/NotFound.vue').default },

        { path: "/", component: require('../pages/Home.vue').default, meta: { forAuth: true } },
        { path: "/home", component: require('../pages/Home.vue').default, meta: { forAuth: true } },

        { path: "/addresses", component: require('../components/address/Index.vue').default, meta: { forAuth: true, group: 'Address', guard_name: 'address-view' } },

        { path: "/authors", component: require('../components/author/Index.vue').default, meta: { forAuth: true, group: 'Author', guard_name: 'author-view' } },
        { path: "/authors/create", component: require('../components/author/Create.vue').default, meta: { forAuth: true, group: 'Author', guard_name: 'author-create' } },
        { path: "/authors/:id/edit", component: require('../components/author/Edit.vue').default, meta: { forAuth: true, group: 'Author', guard_name: 'author-edit' } },

        { path: "/books", component: require('../components/book/Index.vue').default, meta: { forAuth: true, group: 'Book', guard_name: 'book-view' } },
        { path: "/books/create", component: require('../components/book/Create.vue').default, meta: { forAuth: true, group: 'Book', guard_name: 'book-create' } },
        { path: "/books/:slug/edit", component: require('../components/book/Edit.vue').default, meta: { forAuth: true, group: 'Book', guard_name: 'book-edit' } },

        { path: "/tags", component: require('../components/tag/Index.vue').default, meta: { forAuth: true, group: 'Tag', guard_name: 'tag-view' } },
        { path: "/tags/create", component: require('../components/tag/Create.vue').default, meta: { forAuth: true, group: 'Tag', guard_name: 'tag-create' } },
        { path: "/tags/:slug/edit", component: require('../components/tag/Edit.vue').default, meta: { forAuth: true, group: 'Tag', guard_name: 'tag-edit' } },

        { path: "/banners", component: require('../components/banner/Index.vue').default, meta: { forAuth: true, group: 'Banner', guard_name: 'banner-view' } },
        { path: "/banners/create", component: require('../components/banner/Create.vue').default, meta: { forAuth: true, group: 'Banner', guard_name: 'banner-create' } },
        { path: "/banners/:slug/edit", component: require('../components/banner/Edit.vue').default, meta: { forAuth: true, group: 'Banner', guard_name: 'banner-edit' } },

        { path: "/boxes", component: require('../components/box/Index.vue').default, meta: { forAuth: true, group: 'Box', guard_name: 'box-view' } },
        { path: "/boxes/create", component: require('../components/box/Create.vue').default, meta: { forAuth: true, group: 'Box', guard_name: 'box-create' } },
        { path: "/boxes/:id/edit", component: require('../components/box/Edit.vue').default, meta: { forAuth: true, group: 'Box', guard_name: 'box-edit' } },

        { path: "/categories", component: require('../components/category/Index.vue').default, meta: { forAuth: true, group: 'Category', guard_name: 'category-view' } },
        { path: "/categories/create", component: require('../components/category/Create.vue').default, meta: { forAuth: true, group: 'Category', guard_name: 'category-create' } },
        { path: "/categories/sort", component: require('../components/category/Sort.vue').default, meta: { forAuth: true, group: 'Category', guard_name: 'category-sort' } },
        { path: "/categories/:slug/edit", component: require('../components/category/Edit.vue').default, meta: { forAuth: true, group: 'Category', guard_name: 'category-edit' } },

        { path: "/deliveries", component: require('../components/delivery/Index.vue').default, meta: { forAuth: true, group: 'Delivery', guard_name: 'delivery-view' } },
        { path: "/deliveries/:slug/edit", component: require('../components/delivery/Edit.vue').default, meta: { forAuth: true, group: 'Delivery', guard_name: 'delivery-edit' } },

        { path: "/statuses", component: require('../components/status/Index.vue').default, meta: { forAuth: true, group: 'Status', guard_name: 'status-view' } },

        { path: "/category-news", component: require('../components/category-news/Index.vue').default, meta: { forAuth: true, group: 'CategoryNews', guard_name: 'category-news-view' } },
        { path: "/category-news/create", component: require('../components/category-news/Create.vue').default, meta: { forAuth: true, group: 'CategoryNews', guard_name: 'category-news-create' } },
        { path: "/category-news/:id/edit", component: require('../components/category-news/Edit.vue').default, meta: { forAuth: true, group: 'CategoryNews', guard_name: 'category-news-edit' } },

        { path: "/carts", component: require('../components/cart/Index.vue').default, meta: { forAuth: true, group: 'Cart', guard_name: 'cart-view' } },
        { path: "/carts/:id/edit", component: require('../components/cart/Edit.vue').default, meta: { forAuth: true, group: 'Cart', guard_name: 'cart-create' } },

        //{ path: "/discount-types", component: require('../components/discount-type/Index.vue').default, meta: { forAuth: true, group: 'DiscountType', guard_name: 'discount-type-view' } },
        // { path: "/discount-types/create", component: require('../components/discount-type/Create.vue').default, meta: { forAuth: true, group: 'DiscountType', guard_name: 'discount-type-create' } },
        // { path: "/discount-types/:id/edit", component: require('../components/discount-type/Edit.vue').default, meta: { forAuth: true, group: 'DiscountType', guard_name: 'discount-type-edit' } },
        //
        // { path: "/discounts", component: require('../components/discount/Index.vue').default, meta: { forAuth: true, group: 'Discount', guard_name: 'discount-view' } },
        // { path: "/discounts/create", component: require('../components/discount/Create.vue').default, meta: { forAuth: true, group: 'Discount', guard_name: 'discount-create' } },
        // { path: "/discounts/:id/edit", component: require('../components/discount/Edit.vue').default, meta: { forAuth: true, group: 'Discount', guard_name: 'discount-edit' } },
        //
        // { path: "/promo-codes", component: require('../components/promo-code/Index.vue').default, meta: { forAuth: true, group: 'PromoCode', guard_name: 'promo-code-view' } },
        // { path: "/promo-codes/create", component: require('../components/promo-code/Create.vue').default, meta: { forAuth: true, group: 'PromoCode', guard_name: 'promo-code-create' } },
        // { path: "/promo-codes/:id/edit", component: require('../components/promo-code/Edit.vue').default, meta: { forAuth: true, group: 'PromoCode', guard_name: 'promo-code-edit' } },

        { path: "/cities", component: require('../components/city/Index.vue').default, meta: { forAuth: true, group: 'City', guard_name: 'city-view' } },
        { path: "/cities/create", component: require('../components/city/Create.vue').default, meta: { forAuth: true, group: 'City', guard_name: 'city-create' } },
        { path: "/cities/:id/edit", component: require('../components/city/Edit.vue').default, meta: { forAuth: true, group: 'City', guard_name: 'city-edit' } },

        { path: "/groups", component: require('../components/group/Index.vue').default, meta: { forAuth: true, group: 'Group', guard_name: 'group-view' } },
        { path: "/groups/create", component: require('../components/group/Create.vue').default, meta: { forAuth: true, group: 'Group', guard_name: 'group-create' } },
        { path: "/groups/:id/edit", component: require('../components/group/Edit.vue').default, meta: { forAuth: true, group: 'Group', guard_name: 'group-edit' } },

        { path: "/dictionaries", component: require('../components/dictionary/Index.vue').default, meta: { forAuth: true, group: 'Language', guard_name: 'language-view' } },
        { path: "/dictionaries/:id/edit", component: require('../components/dictionary/Edit.vue').default, meta: { forAuth: true, group: 'Language', guard_name: 'language-edit' } },

        { path: "/documents", component: require('../components/document/Index.vue').default, meta: { forAuth: true, group: 'Document', guard_name: 'document-view' } },
        { path: "/documents/create", component: require('../components/document/Create.vue').default, meta: { forAuth: true, group: 'Document', guard_name: 'document-create' } },
        { path: "/documents/:id/edit", component: require('../components/document/Edit.vue').default, meta: { forAuth: true, group: 'Document', guard_name: 'document-edit' } },

        { path: "/file-manager", component: require('../components/helper/FileManager.vue').default, meta: { forAuth: true, group: 'City', guard_name: 'city-view' } },

        { path: "/login", component: require('../components/auth/Login.vue').default, meta: { forVisitors: true } },
        { path: "/register", component: require('../components/auth/Register.vue').default, meta: { forVisitors: true } },
        { path: "/logout", component: require('../components/auth/Logout.vue').default, meta: { forAuth: true } },

        { path: "/forgotten-password", component: require('../components/auth/password/Email.vue').default, meta: { forVisitors: true } },
        { path: "/forgotten-password/:token", component: require('../components/auth/password/Reset.vue').default, meta: { forVisitors: true } },

        { path: "/languages", component: require('../components/language/Index.vue').default, meta: { forAuth: true, group: 'Language', guard_name: 'language-view' } },
        { path: "/languages/create", component: require('../components/language/Create.vue').default, meta: { forAuth: true, group: 'Language', guard_name: 'language-create' } },
        { path: "/languages/:id/edit", component: require('../components/language/Edit.vue').default, meta: { forAuth: true, group: 'Language', guard_name: 'language-edit' } },

        { path: "/menus", component: require('../components/menu/Index.vue').default, meta: { forAuth: true, group: 'Menu', guard_name: 'menu-view' } },
        { path: "/menus/create", component: require('../components/menu/Create.vue').default, meta: { forAuth: true, group: 'Menu', guard_name: 'menu-create' } },
        { path: "/menus/:id/edit", component: require('../components/menu/Edit.vue').default, meta: { forAuth: true, group: 'Menu', guard_name: 'menu-edit' } },
        { path: "/menus/:id/sort", component: require('../components/menu/Sort.vue').default, meta: { forAuth: true, group: 'Menu', guard_name: 'menu-edit' } },

        { path: "/menu-items", component: require('../components/menu-item/Index.vue').default, meta: { forAuth: true, group: 'Menu', guard_name: 'menu-view' } },

        { path: "/links", component: require('../components/link/Index.vue').default, meta: { forAuth: true, group: 'Link', guard_name: 'link-view' } },
        { path: "/links/create", component: require('../components/link/Create.vue').default, meta: { forAuth: true, group: 'Link', guard_name: 'link-create' } },
        { path: "/links/:id/edit", component: require('../components/link/Edit.vue').default, meta: { forAuth: true, group: 'Link', guard_name: 'link-edit' } },

        { path: "/pages", component: require('../components/page/Index.vue').default, meta: { forAuth: true, group: 'Page', guard_name: 'page-view' } },
        { path: "/pages/create", component: require('../components/page/Create.vue').default, meta: { forAuth: true, group: 'Page', guard_name: 'page-create' } },
        { path: "/pages/:slug/edit", component: require('../components/page/Edit.vue').default, meta: { forAuth: true, group: 'Page', guard_name: 'page-edit' } },

        { path: "/permission-groups", component: require('../components/permission-group/Index.vue').default, meta: { forAuth: true, group: 'Permission group', guard_name: 'permission-group-view' } },
        { path: "/permission-groups/create", component: require('../components/permission-group/Create.vue').default, meta: { forAuth: true, group: 'Permission group', guard_name: 'permission-group-create' } },
        { path: "/permission-groups/:id/edit", component: require('../components/permission-group/Edit.vue').default, meta: { forAuth: true, group: 'Permission group', guard_name: 'permission-group-edit' } },

        { path: "/permissions", component: require('../components/permission/Index.vue').default, meta: { forAuth: true, group: 'Permission', guard_name: 'permission-view' } },
        { path: "/permissions/create", component: require('../components/permission/Create.vue').default, meta: { forAuth: true, group: 'Permission', guard_name: 'permission-create' } },
        { path: "/permissions/:id/edit", component: require('../components/permission/Edit.vue').default, meta: { forAuth: true, group: 'Permission', guard_name: 'permission-edit' } },

        { path: "/posts", component: require('../components/post/Index.vue').default, meta: { forAuth: true, group: 'Post', guard_name: 'post-view' } },
        { path: "/posts/create", component: require('../components/post/Create.vue').default, meta: { forAuth: true, group: 'Post', guard_name: 'post-create' } },
        { path: "/posts/:slug/edit", component: require('../components/post/Edit.vue').default, meta: { forAuth: true, group: 'Post', guard_name: 'post-edit' } },

        { path: "/roles", component: require('../components/role/Index.vue').default, meta: { forAuth: true, group: 'Role', guard_name: 'role-view' } },
        { path: "/roles/create", component: require('../components/role/Create.vue').default, meta: { forAuth: true, group: 'Role', guard_name: 'role-view' } },
        { path: "/roles/:id/edit", component: require('../components/role/Edit.vue').default, meta: { forAuth: true, group: 'Role', guard_name: 'role-view' } },
        { path: "/roles/:id/permissions", component: require('../components/role/Permission.vue').default, meta: { forAuth: true, group: 'Role', guard_name: 'role-view' } },

        { path: "/users", component: require('../components/user/Index.vue').default, meta: { forAuth: true, group: 'User', guard_name: 'user-view' } },
        { path: "/users/create", component: require('../components/user/Create.vue').default, meta: { forAuth: true, group: 'User', guard_name: 'user-create' } },
        { path: "/users/:id/edit", component: require('../components/user/Edit.vue').default, meta: { forAuth: true, group: 'User', guard_name: 'user-edit' } },

        { path: "/profile", component: require('../components/profile/Edit.vue').default, meta: { forAuth: true } },
        
        { path: "/newsletters", component: require('../components/newsletter/Index.vue').default, meta: { forAuth: false, group: 'Newsletter', } },

        { path: "/schools", component: require('../components/school/Index.vue').default, meta: { forAuth: true, group: 'School',  guard_name: 'school-view'} },
        { path: "/schools/create", component: require('../components/school/Create.vue').default, meta: { forAuth: true, group: 'School', guard_name: 'school-create' } },
        { path: "/schools/:id/edit", component: require('../components/school/Edit.vue').default, meta: { forAuth: true, group: 'School', guard_name: 'school-edit' } },

        { path: "/school-classes", component: require('../components/school-class/Index.vue').default, meta: { forAuth: true, group: 'School Class',  guard_name: 'school-class-view'} },
        { path: "/school-classes/create", component: require('../components/school-class/Create.vue').default, meta: { forAuth: true, group: 'School Class', guard_name: 'school-class-create' } },
        { path: "/school-classes/:id/edit", component: require('../components/school-class/Edit.vue').default, meta: { forAuth: true, group: 'School Class', guard_name: 'school-class-edit' } },

        { path: "/digital-books", component: require('../components/digital-book/Index.vue').default, meta: { forAuth: true, group: 'Digital Book',  guard_name: 'digital-book-view'} },
        { path: "/digital-books/create", component: require('../components/digital-book/Create.vue').default, meta: { forAuth: true, group: 'Digital Book', guard_name: 'digital-book-create' } },
        { path: "/digital-books/:id/edit", component: require('../components/digital-book/Edit.vue').default, meta: { forAuth: true, group: 'Digital Book', guard_name: 'digital-book-edit' } },

        { path: "/schools/codes/show", component: require('../components/digital-book-code/Index.vue').default, meta: { forAuth: true, group: 'Digital Book Code', guard_name: 'digital-book-code-edit' } },
        { path: "/digital-book/codes/create", component: require('../components/digital-book-code/Create.vue').default, meta: { forAuth: true, group: 'Digital Book Code', guard_name: 'digital-book-code-create' } },
    ],

    linkActiveClass: 'active',
});

router.beforeEach(beforeEach);

export default router;
