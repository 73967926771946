<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>

                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">
                    <form>
                        <v-container>
                            <v-layout v-for="(value, key) in words" :key="key">

                                <v-flex xs6 class="mr-3">
                                    <v-text-field
                                            v-model="keys[key]"
                                            label="Key"
                                            required>
                                    </v-text-field>
                                </v-flex>

                                <v-spacer></v-spacer>

                                <v-flex xs6>
                                    <v-text-field
                                            v-model="words[key]"
                                            label="Value"
                                            required>
                                    </v-text-field>
                                </v-flex>

                                <v-btn fab dark small color="error" v-if="checkGuard('language-delete')">
                                    <v-icon @click="removeRow(key)">mdi-minus</v-icon>
                                </v-btn>

                            </v-layout>
                        </v-container>

                        <v-btn @click="submit()" color="primary" tile class="mr-3">submit</v-btn>
                        <v-btn @click="addRow()" color="info" v-if="checkGuard('language-delete')" tile>add row</v-btn>
                    </form>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard';

    export default {
        data () {
            return {
                fillable: [
                    {
                        field: 'translations',
                        type: 'json'
                    }
                ],
                dictionary: {},
            }
        },

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            language(){
                return this.$store.getters['language/getLanguage'];
            },
            words(){
                return this.$store.getters['dictionary/getWords'];
            },
            keys(){
                return this.$store.getters['dictionary/getKeys'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.dictionaries, 'Dictionaries'),
                        disabled: false,
                        to: '/dictionaries',
                        exact: true,
                    },
                    {
                        text: this.language.title,
                        disabled: true,
                    },
                ];
            },
        },

        mixins: [CheckGuard,],

        async mounted(){
            await this.getLanguage();
            await this.getDictionary();
        },

        methods: {
            async getLanguage(){
                await this.$store.dispatch('language/show', this.$route.params.id);
            },
            async getDictionary(){
                await this.$store.dispatch('dictionary/show', this.$route.params.id);
            },
            async submit(){
                let translations = {};
                this.keys.forEach((key, i) => {
                    if(key != '') translations[key] = this.words[i];
                });
                this.dictionary.translations = translations;

                let data = fillForm(this.fillable, this.dictionary, 'PUT');
                await this.$store.dispatch('dictionary/update', [data, this.$route.params.id]);
            },
            addRow(){
                this.$store.commit('dictionary/addRow');
            },
            removeRow(index){
                this.$store.commit('dictionary/removeRow', index);
            },
        },
    }
</script>