<template>
    <form v-if="role">

        <v-text-field
                v-model="role.name"
                :error-messages="error_messages.name"
                :label="checkTranslation(user.profile.translations.name, 'Name')"
                required
        ></v-text-field>

        <v-text-field
                v-model="role.guard_name"
                :error-messages="error_messages.guard_name"
                :label="checkTranslation(user.profile.translations.guard_name, 'Guard name')"
                required
        ></v-text-field>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="role.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click="submit()" tile color="primary" class="mr-3">{{ checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" tile color="secondary">{{ checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>

    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            role() {
                return this.$store.getters['role/getRole'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear(){
                this.$store.dispatch('role/clear');
            },
        },
    }
</script>