<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>
                <v-card class="pa-4">
                    <v-card-title>
                        <v-flex xs4 d-flex v-if="showSelect">
                            <v-select
                                @change="multipleChange()"
                                v-model="multiple"
                                :items="dropdown_button"
                                :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                :disabled="disabled"
                                item-text="title"
                                item-value="key"
                                solo
                            ></v-select>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex xs4 d-flex v-if="showSelect">
                            <v-select
                                class="mr-4"
                                @change="searchPages()"
                                v-if="menus"
                                v-model="menu_id"
                                :items="menus"
                                :label="checkTranslation(user.profile.translations.menu, 'Menu')"
                                clearable
                                item-text="title"
                                item-value="id"
                            ></v-select>
                        </v-flex>
                        <v-flex xs5 d-flex>

                            <v-text-field
                                v-model="search"
                                label="Pretraga po nazivu"
                                @keyup.enter="searchPages()"
                                class="mr-4"
                            ></v-text-field>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5 mr-2" icon color="primary" v-on="on" @click="searchPages()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.search, 'Search') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5" icon color="error" v-on="on" @click="resetSearch()">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.reset, 'Reset') }}</span>
                            </v-tooltip>

                        </v-flex>

                    </v-card-title>

                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        :options.sync="pagination"
                        :server-items-length="total_items"
                        :loading="loading"
                        :items-per-page="50"
                        class="elevation-1"
                        :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                        :show-select="showSelect"
                    >
                        <template v-slot:item.is_visible="{ item }">
                            <v-icon @click="updateVisible(item)">{{ isVisible(item.is_visible) }}</v-icon>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-tooltip bottom v-if="checkGuard('page-edit')">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mr-2"
                                        text
                                        icon
                                        color="primary"
                                        :to="'/pages/' + item.slug + '/edit'"
                                        v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mr-2"
                                        text icon color="error"
                                        @click="destroy(item)"
                                        v-if="checkGuard('page-delete')"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.delete, 'Delete') }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-fab-transition v-if="checkGuard('link-create')">
                <v-btn :color="'primary'" dark fab fixed bottom right to="/pages/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';
    import fields from './fields.json';

    export default {
        data() {
            return {
                model: 'Page',
                search: '',
                menu_id: undefined,
                showSelect: false,
                fillable: fields,
            };
        },
        mixins: [MultipleSelect,],
        computed: {
            items() {
                return this.$store.getters['page/getPages'];
            },
            menus() {
                return this.$store.getters['menu/getMenus'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.pages, 'Pages'),
                        disabled: true,
                    },
                ];
            },
            headers(){
                return [
                    {text: 'ID', align: 'left', value: 'id'},
                    {text: 'TITLE', align: 'left', value: 'title'},
                    {text: 'MENU', align: 'left', value: 'menu.title'},
                    {text: 'IS VISIBLE', value: 'is_visible'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ];
            }
        },
        async mounted(){
            this.translate();
            await this.getMenus();
        },
        methods: {
            async getPages() {
                await this.$store.dispatch('page/index', this.pagination);
            },
            async getMenus() {
                await this.$store.dispatch('menu/index', {
                    sortBy: ['id'],
                    sortDesc: [1],
                    lists: true,
                });
            },
            async destroy(row){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('page/destroy', { ...row, vuetify });
            },
            translate(){
                this.headers[1].text = this.user.profile.translations.title.toUpperCase();
                this.headers[2].text = this.user.profile.translations.menu.toUpperCase();
                this.headers[3].text = this.user.profile.translations.is_visible.toUpperCase();
                this.headers[4].text = this.user.profile.translations.action.toUpperCase();
            },
            async searchPages() {
                this.pagination.title = this.search;
                this.pagination.menu_id = this.menu_id;
                this.pagination.page = 1;
                await this.getPages();
            },
            async resetSearch() {
                this.search = '';
                this.pagination.title = undefined;
                this.pagination.menu_id = undefined;
                this.pagination.page = 1;
                await this.getPages();
            },
            async updateVisible(item) {
                item.is_visible = !item.is_visible;
                let data = fillForm(this.fillable, item, 'PUT');

                await this.$store.dispatch('page/update', [data, item.slug],);
            },
        },
        watch: {
            pagination: {
                handler () {
                    this.getPages();
                },
                deep: true
            },
        },

        async beforeDestroy(){
            await this.$store.dispatch('page/clearAll');
        },
    }
</script>

<style scoped>

</style>
