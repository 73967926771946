<template>
    <vue-dropzone :options="dropzoneOptions" id="myVueDropzone" @vdropzone-queue-complete="uploadDone()" v-if="book"></vue-dropzone>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {

        props: ['book'],

        components: {
            vueDropzone: vue2Dropzone,
        },

        computed: {
            dropzoneOptions() {
                return {
                    url: domain + 'api/books/' + this.book.slug + '/galleries',
                    thumbnailWidth: 287,
                    thumbnailHeight: 405,
                    maxFilesize: 2,
                    headers: {'Authorization': 'Bearer ' + Vue.auth.getToken()},
                    paramName: 'image',
                    createImageThumbnails: false,
                };
            },
        },

        mounted() {
            this.getImages();
        },

        methods: {
            getImages() {
                this.$store.dispatch('gallery/index', this.book.slug);
            },
            uploadDone(){
                this.getImages();
            },
        },
    }
</script>

<style>
    .dz-preview, .dz-details{
        z-index: 1;
    }
</style>
