<template>
    <form v-if="category">

        <v-select
            v-if="categories"
            v-model="category.parent_id"
            :items="categories"
            :label="checkTranslation(user.profile.translations.parent_categories, 'Parent Categories')"
            :error-messages="error_messages.parent_id"
            clearable
            item-text="title"
            item-value="id"
        ></v-select>
        <v-text-field
            v-model="category.title"
            :error-messages="error_messages.title"
            :label="checkTranslation(user.profile.translations.title, 'Title')"
            required
        ></v-text-field>
        <v-text-field
            v-model="category.url_id"
            :error-messages="error_messages.title"
            :label="checkTranslation(user.profile.translations.url_id_category, 'Url ID')"
        ></v-text-field>
        <textarea-ckeditor
                :label="checkTranslation(user.profile.translations.text, 'Text')"
                :value="category.text"
                :required="false"
                @changeValue="category.text = $event"
        ></textarea-ckeditor>
        <v-select
            v-if="templateTypes"
            v-model="category.type"
            :items="templateTypes"
            :label="checkTranslation(user.profile.translations.template_type, 'Template type')"
            :error-messages="error_messages.type"
            clearable
            item-text="name"
            item-value="value"
        ></v-select>

        <upload-image-cropper
            :imgWidth="640"
            :imgHeight="480"
            :model="category"
            :image="category.image"
            @uploadImage="setImage($event)"
            @removeImage="removeImage()"
        ></upload-image-cropper>
        <v-checkbox
            :label="checkTranslation(user.profile.translations.is_visible, 'Visible')"
            v-model="category.is_visible"
            color="primary"
        ></v-checkbox>
        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            category() {
                return this.$store.getters['category/getCategory'];
            },
            categories() {
                return this.$store.getters['category/getCategories'];
            },
            templateTypes() {
                return this.$store.getters['category/getTemplateTypes'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('category/clear');
            },
            setImage(image) {
                this.category.image = image.file;
            },
            removeImage(image) {
                this.category.image = null;
                this.category.remove_image = true;
            },
        },
    }
</script>
