<template>

</template>

<script>
    export default{
        async mounted(){
            await this.$auth.destroyToken();
            await this.$store.commit('user/clear');
            await this.$store.commit('auth/clear');
            await this.$router.push('/login');
        },
    }
</script>
