export const index = (state, permission_groups) => {
    state.permission_groups = permission_groups;
};

export const show = (state, permission_group) => {
    state.permission_group = permission_group;
};

export const clear = state => {
    state.permission_group = {
        'name': '',
        'order': '',
        'is_visible': false,
    };
};

export const clearAll = state => {
    state.permission_groups = [];
};