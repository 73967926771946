import swal from 'sweetalert2';
import router from '../../../router';
import vuetifyPagination from '../../../packages/vuetifyPagination';

/**
 * method used to get banner from Backend and store in Vuex
 *
 * @param {object} context
 * @param {object} pagination
 */
export const index = async (context, pagination) => {
    context.dispatch('common/changePagination', pagination, {root: true});

    const {lists, filterOptions} = pagination;

    await axios.get(`api/banner?${vuetifyPagination(pagination)}`)
        .then(res => {
            context.commit('index', filterOptions !== undefined ? filter(res.data.data) : res.data.data);
            if (!lists) {
                context.dispatch('common/changeTotal', res.data.meta.total, {root: true});
            }
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to store banner item to the Backend and and store in Vuex
 *
 * @param {object} context
 * @param {object} callback - banner item
 */
export const store = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/banner', callback)
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            router.push('/banners');
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to get banner item from Backend and store in Vuex
 *
 * @param {object} context
 * @param {int} slug - banner_slug
 */
export const show = async (context, slug) => {

    await axios.get('api/banner/' + slug)
        .then(res => {
            context.commit('show', res.data.data);
        })
        .catch(e => {
            console.log(e);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to update banner item to the Backend and store in Vuex
 *
 * @param {object} context
 * @param {array} callback - (array[0] - banner_id, array[1] - data banner)
 */
export const update = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/banner/' + callback[1], callback[0])
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            context.commit('show', res.data.data);
            context.dispatch('common/changeErrorMessages', [], {root: true});
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to remove banner item from Backend and store in Vuex
 *
 * @param {object} dispatch
 * @param {object} commit
 * @param {object} getters
 * @param {object} rootGetters
 * @param {object} callback - banner item
 */
export const destroy = async ({dispatch, commit, getters, rootGetters}, callback) => {
    let user = rootGetters['user/getUserProfile'];
    await swal.fire({
        title: user.profile.translations.are_you_sure,
        text: user.profile.translations.you_wont_be_able_to_revert_this,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: callback.vuetify.theme.primary,
        cancelButtonColor: callback.vuetify.theme.secondary,
        confirmButtonText: checkTranslation(user.profile.translations.yes_delete_it, 'Yes delete it!'),
        cancelButtonText: checkTranslation(user.profile.translations.cancel, 'Cancel'),
    }).then(async (result) => {
        if (result.value) {
            await axios.delete('api/banner/' + callback.slug)
                .then(res => {
                    swal.fire(
                        checkTranslation(user.profile.translations.deleted, 'Deleted'),
                        checkTranslation(user.profile.translations.your_file_has_been_deleted, 'Your file has been deleted!'),
                        'success',
                    );
                    dispatch('index', rootGetters['common/getPagination']);
                })
                .catch(e => {
                    console.log(e);
                    context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
                });
        }
    });
};

/**
 * method used to clear banner properties and error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.commit('clear');
    context.dispatch('common/changeErrorMessages', [], {root: true});
};

/**
 * method used to clear banners properties
 *
 * @param {object} context
 */
export const clearAll = async context => {
    await context.commit('clearAll');
};

/**
 * method use to prepare banners for select box
 *
 * @param data
 */
function filter(data) {
    let res = [];
    let lastParent = null;
    data.forEach((item) => {

        if (lastParent !== null && item.parent === 0) {
            res.push({divider: true});
        }

        if (item.parent === 0) {
            res.push({header: item.title});
        }

        item.children.forEach((child) => {
            res.push({id: child.id, title: child.title});
        });

        lastParent = item.parent;
    });
    return res;
};
