<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields :edit="true" @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        mixins: [CheckGuard,],

        computed: {
            delivery() {
                return this.$store.getters['delivery/getDelivery'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.delivery_price, 'Delivery Price'),
                        disabled: false,
                        to: '/deliveries',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted(){
            this.clear();
            await this.getDelivery();
        },

        methods: {
            async getDelivery(){
                await this.$store.dispatch('delivery/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});

                let data = fillForm(this.fillable, this.delivery, 'PUT');
                await this.$store.dispatch('delivery/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('delivery/clear');
            },
        },
    }
</script>
