import swal from 'sweetalert2';
import router from '../../../router';
import vuetifyPagination from '../../../packages/vuetifyPagination';

/**
 * method used to get menus from Backend and store in Vuex
 *
 * @param {object} context
 * @param {object} pagination
 */
export const index = async (context, pagination) => {
    context.dispatch('common/changePagination', pagination, {root: true});

    const {lists} = pagination;

    await axios.get(`api/menus?${vuetifyPagination(pagination)}`)
        .then(res => {
            context.commit('index', res.data.data);
            if (!lists) {
                context.dispatch('common/changeTotal', res.data.meta.total, {root: true});
            }
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to store menu item to the Backend and and store in Vuex
 *
 * @param {object} context
 * @param {object} callback - menu item
 */
export const store = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/menus', callback)
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            router.push('/menu-items');
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to get menu item from Backend and store in Vuex
 *
 * @param {object} context
 * @param {int} id - menu_id
 */
export const show = async (context, id) => {

    await axios.get('api/menus/' + id)
        .then(res => {
            context.commit('show', res.data.data);
        })
        .catch(e => {
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to update menu item to the Backend and store in Vuex
 *
 * @param {object} context
 * @param {array} callback - (array[0] - menu_id, array[1] - data menu)
 */
export const update = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/menus/' + callback[1], callback[0])
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            context.commit('show', res.data.data);
            context.dispatch('common/changeErrorMessages', [], {root: true});
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to remove menu item from Backend and store in Vuex
 *
 * @param {object} dispatch
 * @param {object} commit
 * @param {object} getters
 * @param {object} rootGetters
 * @param {object} callback - menu item
 */
export const destroy = async ({dispatch, commit, getters, rootGetters}, callback) => {
    let user = rootGetters['user/getUserProfile'];
    await swal.fire({
        title: user.profile.translations.are_you_sure,
        text: user.profile.translations.you_wont_be_able_to_revert_this,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: callback.vuetify.theme.primary,
        cancelButtonColor: callback.vuetify.theme.secondary,
        confirmButtonText: checkTranslation(user.profile.translations.yes_delete_it, 'Yes delete it!'),
        cancelButtonText: checkTranslation(user.profile.translations.cancel, 'Cancel'),
    }).then(async (result) => {
        if (result.value) {
            await axios.delete('api/menus/' + callback.id)
                .then(res => {
                    swal.fire(
                        checkTranslation(user.profile.translations.deleted, 'Deleted'),
                        checkTranslation(user.profile.translations.your_file_has_been_deleted, 'Your file has been deleted!'),
                        'success',
                    );
                    dispatch('itemsIndex', rootGetters['common/getPagination']);
                })
                .catch(e => {
                    console.log(e);
                    context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
                });
        }
    });
};

/**
 * method used to get sort menu links from Backend and update Vuex module
 *
 * @param {object} context
 * @param {integer} id - menu_id
 */
export const index_sort = async (context, id) => {
    await axios.get(`api/menus/${id}/sort`)
        .then(res => {
            context.commit('index_sort', res.data.data);
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to store sort menu links to the Backend and update from Vuex
 *
 * @param {object} context
 * @param {array} callback - (array[0] - menu_id, array[1] - data links)
 */
export const store_sort = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];
    await axios.put(`api/menus/${callback[1]}/sort`, {'links': callback[0]})
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            context.commit('index_sort', res.data.data);
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to clear menu properties and error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.commit('clear');
    context.dispatch('common/changeErrorMessages', [], {root: true});
};

/**
 * method used to clear menu properties
 *
 * @param {object} context
 */
export const clearAll = async context => {
    await context.commit('clearAll');
};

/**
 * method used to get menu-items from Backend and store in Vuex
 *
 * @param {object} context
 * @param {object} pagination
 */
export const itemsIndex = async (context, pagination) => {
    context.dispatch('common/changePagination', pagination, {root: true});

    const {lists} = pagination;

    await axios.get(`api/menu/items?${vuetifyPagination(pagination)}`)
        .then(res => {
            context.commit('index', res.data.data);
            if (!lists) {
                context.dispatch('common/changeTotal', res.data.meta.total, {root: true});
            }
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};
