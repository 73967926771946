<template>
    <v-navigation-drawer
            v-model="drawer"
            clipped
            app
            overflow
    >

        <v-list v-if="load">

            <v-list-item @click="routePush('/')">

                <v-list-item-action>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-action>

                <v-list-item-title>{{ checkTranslation(user.profile.translations.home, 'Home') }}</v-list-item-title>

            </v-list-item>

            <v-list-group
                    prepend-icon="mdi-settings"
                    no-action
                    v-if="checkGuard('language-edit')"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ checkTranslation(user.profile.translations.settings, 'Settings') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(setting, i) in settings" :key="i" @click="routePush(setting[2])" v-if="checkGuard(setting[3])">

                    <v-list-item-title v-text="setting[0]"></v-list-item-title>

                </v-list-item>
            </v-list-group>

            <v-list-group
                    prepend-icon="mdi-account"
                    no-action
                    v-if="checkGuard('user-view') || checkGuard('role-view') || checkGuard('permission-view')"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ checkTranslation(user.profile.translations.users, 'Users') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(user, i) in users" :key="i" @click="routePush(user[2])" v-if="checkGuard(user[3])">

                    <v-list-item-title v-text="user[0]"></v-list-item-title>

                </v-list-item>
            </v-list-group>

            <v-list-group
                    prepend-icon="mdi-menu"
                    no-action
                    v-if="checkGuard('menu-view') || checkGuard('link-view')"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ checkTranslation(user.profile.translations.menus, 'Menus') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(menu, i) in menus" :key="i" @click="routePush(menu[2])" v-if="checkGuard(menu[3])">

                    <v-list-item-title v-text="menu[0]"></v-list-item-title>

                </v-list-item>
            </v-list-group>

            <v-list-group
                    prepend-icon="mdi-shape-outline"
                    no-action
                    v-if="checkGuard('category-news-view') || checkGuard('post-view')"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ checkTranslation(user.profile.translations.news, 'News') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(post, i) in news" :key="i" @click="routePush(post[2])" v-if="checkGuard(post[3])">

                    <v-list-item-title v-text="post[0]"></v-list-item-title>

                </v-list-item>
            </v-list-group>

            <v-list-group
                    prepend-icon="mdi-book-open-page-variant"
                    no-action
                    v-if="checkGuard('category-view') || checkGuard('book-view')"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ checkTranslation(user.profile.translations.books, 'Books') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(book, i) in books" :key="i" @click="routePush(book[2])" v-if="checkGuard(book[3])">

                    <v-list-item-title v-text="book[0]"></v-list-item-title>

                </v-list-item>
            </v-list-group>

            
        </v-list>

        <v-list-item @click="routePush('/newsletters')" v-if="checkGuard('newsletter-view')">

            <v-list-item-action>
                <v-icon>mdi-comment-check-outline</v-icon>
            </v-list-item-action>

            <v-list-item-title>{{ checkTranslation(user.profile.translations.newsletter, 'Newsletter') }}</v-list-item-title>

        </v-list-item>

        <v-list-item @click="routePush('/boxes')" v-if="checkGuard('box-view')">

            <v-list-item-action>
                <v-icon>mdi-folder-multiple-image</v-icon>
            </v-list-item-action>

            <v-list-item-title>{{ checkTranslation(user.profile.translations.home_images, 'Homepage images') }}</v-list-item-title>

        </v-list-item>

        <v-list-item @click="routePush('/banners')" v-if="checkGuard('banner-view')">

            <v-list-item-action>
                <v-icon>mdi-card-plus-outline</v-icon>
            </v-list-item-action>

            <v-list-item-title>{{ checkTranslation(user.profile.translations.banners, 'Banners') }}</v-list-item-title>

        </v-list-item>

        <v-list-group
            prepend-icon="mdi mdi-monitor"
            no-action
            v-if="checkGuard('school-view') || checkGuard('digital-book-view') || checkGuard('school-class-view')"
        >
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>{{ checkTranslation(user.profile.translations.digital_books, 'Digital Books') }}</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item
                v-for="(digital_book, i) in digital_books"
                :key="i"
                @click="routePush(digital_book[2])"
                v-if="checkGuard(digital_book[3])"
            >
                <v-list-item-title v-text="digital_book[0]"></v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-group
                prepend-icon="mdi-cart"
                no-action
                v-if="checkGuard('cart-view') || checkGuard('city-view') || checkGuard('address-view') || checkGuard('delivery-view')"
        >
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>{{ checkTranslation(user.profile.translations.carts, 'Carts') }}</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item v-for="(cart, i) in carts" :key="i" @click="routePush(cart[2])" v-if="checkGuard(cart[3])">

                <v-list-item-title v-text="cart[0]"></v-list-item-title>

            </v-list-item>
        </v-list-group>

        <v-list-group
            prepend-icon="mdi-cart-off"
            no-action
            v-if="checkGuard('discount-type-view') || checkGuard('discount-view') || checkGuard('promo-code-view')"
        >
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>Discounts</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item v-for="(discount, i) in discounts" :key="i" @click="routePush(cart[2])">

                <v-list-item-title v-text="discount[0]"></v-list-item-title>

            </v-list-item>
        </v-list-group>

        <v-list-item @click="routePush('/file-manager')" v-if="checkGuard('city-view')">

            <v-list-item-action>
                <v-icon>mdi-clipboard-file</v-icon>
            </v-list-item-action>

            <v-list-item-title>{{ checkTranslation(user.profile.translations.file_manager, 'File Manager') }}</v-list-item-title>

        </v-list-item>

    </v-navigation-drawer>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard';

    export default {
        data() {
            return {
                /** name, icon, route, permission */
                settings: [
                    ['Languages', 'mdi-flag', '/languages', 'language-create'],
                    ['Dictionaries', 'mdi-flag', '/dictionaries', 'language-create'],
                ],
                users: [
                    ['Users', 'account_box', '/users', 'user-view'],
                    ['Roles', 'pan_tool', '/roles', 'role-edit'],
                    ['Permission Groups', 'done_all', '/permission-groups', 'permission-group-view'],
                    ['Permissions', 'done', '/permissions', 'permission-view'],
                ],
                menus: [
                    ['Carts', 'mdi-menu', '/menus', 'menu-view'],
                    ['Menu Items', 'mdi-menu', '/menu-items', 'menu-view'],
                    ['Pages', 'mdi-city', '/pages', 'page-view'],

                ],
                news: [
                    ['Categories', 'mdi-shape-outline', '/category-news', 'category-news-view'],
                    ['Posts', 'mdi-shape-outline', '/posts', 'post-view'],
                ],
                books: [
                    ['Categories', 'mdi-shape-outline', '/categories', 'category-view'],
                    ['Sort Categories', 'mdi-sort', '/categories/sort', 'category-sort'],
                    ['Books', 'mdi-shape-outline', '/books', 'book-view'],
                    ['Authors', 'mdi-account-details', '/authors', 'author-view'],
                    ['Tags', 'mdi-account-details', '/tags', 'tag-view'],
                ],
                documents: [
                    ['Group', 'mdi-folder', '/groups', 'group-view'],
                    ['Documents', 'mdi-file-document-box', '/documents', 'document-view'],
                ],
                carts: [
                    ['Carts', 'mdi-cart', '/carts', 'cart-view'],
                    ['Delivery Price', 'mdi-truck', '/deliveries', 'delivery-view'],
                    // ['Cities', 'mdi-city', '/cities', 'city-view'],
                    // ['Addresses', 'mdi-map-marker', '/addresses', 'address-view'],
                ],
                discounts: [
                    ['Discount Types', 'mdi-format-list-bulleted-type', '/discount-types', 'discount-type-view'],
                    ['Discounts', 'mdi-cart-off', '/discounts', 'discount-view'],
                    ['Promo Codes', 'mdi-barcode', '/promo-codes', 'promo-code-view'],
                ],
                digital_books: [
                    ['Schools', '', '/schools', 'school-view'],
                    ['School classes', '', '/school-classes', 'school-class-view'],
                    ['Digital books', '', '/digital-books', 'digital-book-view'],
                ],
                load: false,
            }
        },
        mixins: [CheckGuard,],
        computed: {
            drawer: {
                get() {
                    return this.$store.getters['nav/getShowLeftBar'];
                },
                set(value) {
                    //
                }
            },
            ability(){
                return this.$store.getters['ability/getPermissionList'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
        },
        methods: {
            routePush(route){
                this.$router.push(route);
            },
        },
        watch: {
            user(){
                this.load = true;
                this.settings[0][0] = checkTranslation(this.user.profile.translations.languages, 'Languages');
                this.settings[1][0] = checkTranslation(this.user.profile.translations.dictionaries, 'Dictionaries');

                this.users[0][0] = checkTranslation(this.user.profile.translations.users, 'Users');
                this.users[1][0] = checkTranslation(this.user.profile.translations.roles, 'Roles');
                this.users[2][0] = checkTranslation(this.user.profile.translations.permission_groups, 'Permission groups');
                this.users[3][0] = checkTranslation(this.user.profile.translations.permissions, 'Permissions');

                this.menus[0][0] = checkTranslation(this.user.profile.translations.menus, 'Menus');
                this.menus[1][0] = checkTranslation(this.user.profile.translations.menu_items, 'Menus Items');
                this.menus[2][0] = checkTranslation(this.user.profile.translations.pages, 'Pages');

                this.documents[0][0] = checkTranslation(this.user.profile.translations.groups, 'Groups');
                this.documents[1][0] = checkTranslation(this.user.profile.translations.documents, 'Documents');

                this.news[0][0] = checkTranslation(this.user.profile.translations.categories, 'Categories');
                this.news[1][0] = checkTranslation(this.user.profile.translations.posts, 'Posts');

                this.books[0][0] = checkTranslation(this.user.profile.translations.categories, 'Categories');
                this.books[1][0] = checkTranslation(this.user.profile.translations.category_sort, 'Sort Category');
                this.books[2][0] = checkTranslation(this.user.profile.translations.books, 'Books');
                this.books[3][0] = checkTranslation(this.user.profile.translations.authors, 'Authors');
                this.books[4][0] = checkTranslation(this.user.profile.translations.tags, 'Tags');

                this.carts[0][0] = checkTranslation(this.user.profile.translations.carts, 'Carts');
                this.carts[1][0] = checkTranslation(this.user.profile.translations.delivery_price, 'Delivery Price');
                // this.carts[2][0] = checkTranslation(this.user.profile.translations.cities, 'Cities');
                // this.carts[3][0] = checkTranslation(this.user.profile.translations.addresses, 'Addresses');

                this.discounts[0][0] = checkTranslation(this.user.profile.translations.discount_types, 'Discount Types');
                this.discounts[1][0] = checkTranslation(this.user.profile.translations.discounts, 'Discounts');
                this.discounts[2][0] = checkTranslation(this.user.profile.translations.promo_codes, 'Promo Codes');

                this.digital_books[0][0] = checkTranslation(this.user.profile.translations.school_codes, 'Schools');
                this.digital_books[1][0] = checkTranslation(this.user.profile.translations.school_classes, 'School Classes');
                this.digital_books[2][0] = checkTranslation(this.user.profile.translations.dig_books, 'Digital Books');
            },
        },
    }
</script>
