export const index = (state, addresses) => {
    state.addresses = addresses;
};

export const show = (state, address) => {
    state.address = address;
};

export const clear = state => {
    state.address = {};
};

export const clearAll = state => {
    state.addresses = [];
};