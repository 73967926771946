<template>
    <form v-if="category">

        <v-select
                v-if="categories"
                v-model="category.parent"
                :items="categories"
                :label="checkTranslation(user.profile.translations.parent_categories, 'Parent Categories')"
                :error-messages="error_messages.parent"
                clearable
                item-text="title"
                item-value="id"
        ></v-select>

        <v-text-field
                v-model="category.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>

        <v-text-field
                v-model="category.order"
                :error-messages="error_messages.order"
                :label="checkTranslation(user.profile.translations.order, 'Order')"
                type="numeric"
                required
        ></v-text-field>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="category.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            category() {
                return this.$store.getters['category_news/getCategory'];
            },
            categories() {
                return this.$store.getters['category_news/getCategories'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('category_news/clear');
            },
        },
    }
</script>