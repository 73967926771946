export const getBanners = (state) => {
    return state.banners;
};

export const getBanner = (state) => {
    return state.banner;
};

export const getTypes = (state) => {
    return state.types;
};
