<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('permission-create')">
                <v-btn :color="'primary'" dark fab fixed bottom right to="/permissions/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>

        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {

        data () {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        mixins: [CheckGuard,],

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            permission(){
                return this.$store.getters['permission/getPermission'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.permissions, 'Permissions'),
                        disabled: false,
                        to: '/permissions',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted(){
            this.clear();
            this.getPermission();
            this.getPermissionGroups();
        },

        methods: {
            async getPermission(){
                await this.$store.dispatch('permission/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                this.permission.locale = this.user.profile.language.locale;
                let data = fillForm(this.fillable, this.permission, 'PUT');
                await this.$store.dispatch('permission/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('permission/clear');
            },
            async getPermissionGroups() {
                await this.$store.dispatch('permission_group/index', {
                    page: 1,
                    itemsPerPage: 50,
                    sortBy: "id",
                    sortDesc: [1],
                    totalItems: 0,
                    lists: 1,
                });
            },
        },
    }
</script>