export default {
    methods: {
        checkGuard(name) {
            let res = false;
            let permissionList = this.$store.getters['ability/getPermissionList'];
            permissionList.forEach(permission => {
                if (name === permission.name) {
                    res = true;
                }
            });
            return res;
        },
    },
}
