export default {
    categories: [],
    category: {
        parent: 0,
        remove_image: false,
    },
    categories_sort: [],
    templateTypes: [
        {
            name : 'Book Template',
            value: 'book_template',
        },
        {
            name : 'Top Template',
            value: 'top_template',
        },
    ],
}
