<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <tree :data="links_sort" draggable="draggable" cross-tree="cross-tree" v-if="links_sort">
                        <div slot-scope="{data, store}">
                            <template v-if="!data.isDragPlaceHolder">
                                <b v-if="data.children && data.children.length" @click="store.toggleOpen(data)">{{data.open ? '-' : '+'}}&nbsp;</b>
                                <span>{{data.title}}</span>
                            </template>
                        </div>
                    </tree>

                    <v-btn @click="submit()" color="primary">{{ checkTranslation(this.user.profile.translations.submit.toUpperCase(), 'Submit') }}</v-btn>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('menu-create')">
                <v-btn :color="'primary'" dark fab fixed bottom right to="/menus/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import { DraggableTree } from 'vue-draggable-nested-tree';
    import CheckGuard from '../../mixins/CheckGuard.js';

    export default {

        data() {
            return {
                loading: true,
            }
        },

        mixins: [CheckGuard],

        components: {
            'tree': DraggableTree,
        },

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            links_sort(){
                return this.$store.getters['menu/getLinksSort'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.menus, 'Menus'),
                        disabled: false,
                        to: '/menus',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.sort, 'Sort'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted(){
            this.getMenu();
        },

        methods: {
            async getMenu(){
                await this.$store.dispatch('menu/index_sort', this.$route.params.id);
            },
            async submit(){
                let data = this.order(this.links_sort);
                await this.$store.dispatch('menu/store_sort', [data, this.$route.params.id]);
            },
            updateLinks(){
                this.links_sort.map((link, index) => {
                    link.order = index + 1;
                })
            },
            order(items){
                return items.map((item) => {
                    if (item.children.length) {
                        return {
                            id: item.id,
                            title: item.title,
                            children: this.order(item.children),
                        };
                    }

                    return {
                        id: item.id,
                        title: item.title,
                    };
                });
            }

        },
    }
</script>

<style>
    .he-tree{
        border: 1px solid #ccc;
        padding: 20px;
        width: 100%;
    }
    .tree-node{
    }
    .tree-node-inner{
        padding: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
    }
    .draggable-placeholder{
    }
    .draggable-placeholder-inner{
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }
</style>
