import moment from 'moment'

export const date = (date) => {
    return moment(date).format('LL');
};

export const ago = (date) => {
    return moment(date).fromNow();
};

export const str_limit = (str, limit) => {
    return str.length > limit ? str.substring(0, limit) + '...' : str;
};
