export const index = (state, roles) => {
    state.roles = roles;
};

export const show = (state, role) => {
    state.role = role;
};

export const clear = state => {
    state.role = {
        'name': '',
        'guard_name': '',
        'is_visible': false,
    };
};

export const clearAll = state => {
    state.roles = [];
};
