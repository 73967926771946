export const index = (state, promo_codes) => {
    state.promo_codes = promo_codes;
};

export const show = (state, promo_code) => {
    state.promo_code = promo_code;
};

export const clear = state => {
    state.promo_code = {
        parent: 0,
    };
};

export const clearAll = state => {
    state.promo_codes = [];
};
