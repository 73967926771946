<template>
    <v-container fluid fill-height>
        <v-layout>
            <h1>Home</h1>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        //
    }
</script>