<template>
    <form v-if="delivery">

        <v-text-field
                v-model="delivery.amount"
                :label="checkTranslation(user.profile.translations.amount, 'Amount')"
                required
        ></v-text-field>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    import router from '../../router';
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            delivery() {
                return this.$store.getters['delivery/getDelivery'];
            }
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('delivery/clear');
            }
        },
    }
</script>