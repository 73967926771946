import  { AbilityBuilder, Ability } from '@casl/ability';

export default function defineAbilitiesFor(permissions) {
    const { rules, can } = AbilityBuilder.extract();

    permissions.forEach(permission => {
        can(permission.group, permission.name);
    });

    return new Ability(rules);
}
