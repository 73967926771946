export const getPermissions = (state) => {
    return state.permissions;
};

export const getPermission = (state) => {
    return state.permission;
};

export const getPermissionIds = (state) => {
    return state.permission_ids;
};
