<template>

    <v-container fluid fill-height>

        <v-layout align-center justify-center>

            <v-flex xs12 sm8 md4>

                <v-card class="elevation-12">

                    <v-toolbar dark color="primary">

                        <v-toolbar-title>Login form</v-toolbar-title>
                        <v-spacer></v-spacer>

                    </v-toolbar>

                    <v-card-text>

                        <v-form>

                            <v-text-field
                                    prepend-icon="mdi-account"
                                    label="Login"
                                    type="email"
                                    name="email"
                                    v-model="auth.email"
                                    :error="error_messages.email == false"
                                    :error-messages="error_messages.email"
                                    @keyup.enter="login()"
                            ></v-text-field>

                            <v-text-field
                                    id="password"
                                    prepend-icon="mdi-lock-outline"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    v-model="auth.password"
                                    :error="error_messages.password == false"
                                    :error-messages="error_messages.password"
                                    @keyup.enter="login()"
                            ></v-text-field>

                        </v-form>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn to="/forgotten-password" small tile class="mr-3">forget a password?</v-btn>
                        <v-btn color="primary" @click="login()" tile>Login</v-btn>
                    </v-card-actions>

                </v-card>

            </v-flex>

        </v-layout>

    </v-container>

</template>

<script>
    export default{
        computed: {
            auth(){
                return this.$store.getters['auth/getAuth'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        mounted(){
            this.clear();
        },
        methods: {
            async clear(){
                await this.$store.commit('nav/setShowLeftBar', false);
                await this.$store.commit('nav/setShowTopBar', false);
                await this.$store.dispatch('common/changeErrorMessages', []);
            },
            async login(){
                await this.$store.dispatch('auth/login');
            },
        },
    }
</script>
