<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <v-tabs
                            v-model="tab"
                            background-color="primary"
                            class="elevation-2"
                            prev-icon="mdi-arrow-left-bold-box-outline"
                            next-icon="mdi-arrow-right-bold-box-outline"
                    >

                        <v-tab href="#info" @click="clear()">{{ checkTranslation(user.profile.translations.profile, 'Profile') }}</v-tab>
                        <v-tab href="#settings" @click="clear()">{{ checkTranslation(user.profile.translations.password, 'Password') }}</v-tab>

                        <!-- Info -->
                        <v-tab-item
                            value="info"
                        >

                                <v-card flat>
                                    <v-card-text>
                                        <v-form v-if="profile">

                                            <v-text-field
                                                    v-model="profile.username"
                                                    :error="error_messages.username == false"
                                                    :error-messages="error_messages.username"
                                                    :label="checkTranslation(user.profile.translations.username, 'Username')"
                                                    required>
                                            </v-text-field>

                                            <v-text-field
                                                    v-model="profile.email"
                                                    :error="error_messages.email == false"
                                                    :error-messages="error_messages.email"
                                                    :label="checkTranslation(user.profile.translations.email, 'Email')"
                                                    required
                                            ></v-text-field>

                                            <v-text-field
                                                    v-model="profile.email_confirmation"
                                                    :error="error_messages.email_confirmation == false"
                                                    :error-messages="error_messages.email_confirmation"
                                                    :label="checkTranslation(user.profile.translations.email_confirmation, 'Email confirmation')"
                                                    required
                                            ></v-text-field>

                                            <v-btn @click="submit()" color="primary" tile>{{ user.profile.translations.submit }}</v-btn>

                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <!-- Info -->

                            <!-- Settings -->
                            <v-tab-item
                                    value="settings"
                            >
                                <v-card class="elevation-12">
                                    <v-card-text>
                                        <v-form v-if="profile">

                                            <v-text-field
                                                    v-model="password.old_password"
                                                    :label="checkTranslation(user.profile.translations.old_password, 'Old password')"
                                                    :error="error_messages.old_password == false"
                                                    :error-messages="error_messages.old_password"
                                                    type="password"
                                                    required>
                                            </v-text-field>

                                            <v-text-field
                                                    v-model="password.password"
                                                    :label="checkTranslation(user.profile.translations.new_password, 'New password')"
                                                    :error="error_messages.password == false"
                                                    :error-messages="error_messages.password"
                                                    type="password"
                                                    required>
                                            </v-text-field>

                                            <v-text-field
                                                    v-model="password.password_confirmation"
                                                    :label="checkTranslation(user.profile.translations.password_confirmation, 'Password confirmation')"
                                                    :error="error_messages.password_confirmation == false"
                                                    :error-messages="error_messages.password_confirmation"
                                                    type="password"
                                                    required>
                                            </v-text-field>

                                            <v-btn @click="updatePassword()" color="primary" tile>{{ user.profile.translations.submit }}</v-btn>

                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <!-- Settings -->

                    </v-tabs>
                </v-card>
            </v-flex>
                <!-- Right side -->

            </v-layout>

    </v-container>
</template>

<script>
    export default {

        data() {
            return {
                tab: null,
                fillable: [
                    {
                        field: 'old_password',
                        type: 'string',
                    },
                    {
                        field: 'password',
                        type: 'string',
                    },
                    {
                        field: 'password_confirmation',
                        type: 'string',
                    },
                ],
                fillable2: [
                    {
                        field: 'username',
                        type: 'string',
                    },
                    {
                        field: 'email',
                        type: 'string',
                    },
                    {
                        field: 'email_confirmation',
                        type: 'string',
                    },
                ],
                password: {},
            };
        },

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            profile(){
                return this.user;
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.profile, 'Profile'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted(){
            this.clear();
        },

        methods: {
            async submit(){
                let data = fillForm(this.fillable2, this.profile, 'PUT');
                await this.$store.dispatch('profile/profileUpdate', [data, this.profile.id]);
            },
            async updatePassword() {
                let data = fillForm(this.fillable, this.password, 'PUT');
                await this.$store.dispatch('profile/passwordUpdate', [data, this.profile.id]);
            },
            clear(){
                this.$store.dispatch('common/changeErrorMessages', []);
            },
        },
    }
</script>
