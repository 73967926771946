<template>

    <v-snackbar
            v-model="loader"
            :left="true"
            :right="true"
            :top="true"
            :bottom="true"
    >
        {{ message }}
        <v-btn tile color="primary">
            close
        </v-btn>
    </v-snackbar>

</template>

<script>
    export default {

        data(){
            return {
                message: 'Please stand by',
            };
        },

        computed: {
            loader(){
                return this.$store.getters['common/getLoading'] || false;
            },
        },

    }
</script>