<template>
    <form v-if="menu">
        <v-text-field
                v-model="menu.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>
        <v-text-field
            :keydown="checkLink"
            v-model="menu.link"
            :error-messages="error_messages.link"
            :label="checkTranslation(user.profile.translations.link, 'Link')"
            required
        ></v-text-field>
        <v-select
            v-if="pages && haveLink"
            v-model="menu.page_id"
            :items="pages"
            :label="checkTranslation(user.profile.translations.page, 'Page')"
            :error-messages="error_messages.page_id"
            clearable
            item-text="title"
            item-value="id"
        ></v-select>
        <v-checkbox
            :label="checkTranslation(user.profile.translations.target_blank, 'Open in new tab')"
            v-model="menu.target_blank"
            color="primary"
        ></v-checkbox>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="menu.is_visible"
                color="primary"
        ></v-checkbox>
        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {
        data () {
            return {
                haveLink: true
            }
        },
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            menu() {
                return this.$store.getters['menu/getMenu'];
            },
            pages() {
                return this.$store.getters['page/getPages'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
            checkLink() {
                if (this.menu.link) {
                    this.haveLink = false;
                } else {
                    this.haveLink = true;
                }
            }
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('menu/clear');
            },
        },
    }
</script>
