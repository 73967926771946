
export const index = (state, users) => {
    state.users = users;
};

export const show = (state, user) => {
    state.user = user;
};

export const show_profile = (state, user_profile) => {
    state.user_profile = user_profile;
};

export const changeDarkTheme = (state, callback) => {
    state.user_profile.profile.dark_theme = callback;
};

export const clear = state => {
    state.user = {};
};

export const clearAll = state => {
    state.users = [];
};
