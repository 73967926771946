<template>

    <v-container fluid fill-height>

        <v-layout align-center justify-center>

            <v-flex xs12 sm8 md4>

                <v-card class="elevation-12">

                    <v-toolbar dark color="primary">

                        <v-toolbar-title>Register form</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>

                        <v-form>

                            <upload-image-cropper :model="auth" :image="auth.image" @uploadImage="setImage($event)"></upload-image-cropper>

                            <v-text-field
                                    prepend-icon="person"
                                    label="Username"
                                    name="username"
                                    type="text"
                                    v-model="auth.username"
                                    :error="error_messages.username == false"
                                    :error-messages="error_messages.username"
                                    @keyup.enter="register()"
                            ></v-text-field>

                            <v-text-field
                                    prepend-icon="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    v-model="auth.email"
                                    :error="error_messages.email == false"
                                    :error-messages="error_messages.email"
                                    @keyup.enter="register()"
                            ></v-text-field>

                            <v-text-field
                                    prepend-icon="lock"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    v-model="auth.password"
                                    :error="error_messages.password == false"
                                    :error-messages="error_messages.password"
                                    @keyup.enter="register()"
                            ></v-text-field>

                            <v-text-field
                                    prepend-icon="lock"
                                    label="Password confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    v-model="auth.password_confirmation"
                                    @keyup.enter="register()"
                            ></v-text-field>

                        </v-form>

                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" to="/login">Go to login form</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="register()">Register</v-btn>
                    </v-card-actions>

                </v-card>

            </v-flex>

        </v-layout>

    </v-container>

</template>

<script>
    export default{
        computed: {
            auth(){
                return this.$store.getters['auth/getAuth'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        mounted(){
            this.clear();
        },
        methods: {
            async clear(){
                await this.$store.commit('nav/setShowLeftBar', false);
                await this.$store.commit('nav/setShowTopBar', false);
                await this.$store.dispatch('common/changeErrorMessages', []);
                await this.$store.dispatch('auth/clear', []);
            },
            async register(){
                await this.$store.dispatch('auth/register');
            },
            setImage(image) {
                this.auth.image = image.file;
            },
        },
    }
</script>