export const index = (state, groups) => {
    state.groups = groups;
};

export const show = (state, group) => {
    state.group = group;
};

export const clear = state => {
    state.group = {};
};

export const clearAll = state => {
    state.groups = [];
};