export const words = (state, words) => {
    state.words = words;
};

export const keys = (state, keys) => {
    state.keys = keys;
};

export const addRow = (state) => {
    state.keys.push('');
    state.words.push('');
};

export const removeRow = (state, index) => {
    state.keys = state.keys.filter((item, key) => {
        return key != index;
    });
    state.words = state.words.filter((item, key) => {
        return key != index;
    });
};

export const clearAll = state => {
    state.words = [];
};