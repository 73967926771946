<template>
        <div>
            <label>{{ checkTranslation(user.profile.translations.digital_books, 'Digital Books') }}</label>
            <v-select
                v-if="digitalBooks"
                v-model="codeCollection.digital_book_id"
                :items="digitalBooks"
                clearable
                item-text="title"
                item-value="id"
            ></v-select>
            <label>{{ checkTranslation(user.profile.translations.number_od_codes, 'Number of codes') }}</label>
            <v-text-field
                v-model="codeCollection.code_count"
                type="number"
                required
            ></v-text-field>
        </div>
</template>
<script>
    export default {
        name: 'form-fields',
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        computed: {
            codeCollection:
            {
                get () {
                    return this.value
                },
                set (codeCollection) {
                    this.$emit('input', codeCollection)
                }
            },
            digitalBooks() {
                return this.$store.getters['digital_book/getDigitalBooks'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
        }
    }
</script>

<style scoped lang="scss">
    .address {
        display: flex;
        flex-direction: column;
    }
    .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }
    label {
        text-align: left;
        margin-bottom: 4px;
    }

    input {
        height: 32px;
        border-radius: 4px;
        border: 1px solid var(--def-border-base);
    }
</style>
