<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <v-card-title>

                        <v-flex xs4 d-flex v-if="showSelect">
                            <v-select
                                    @change="multipleChange()"
                                    v-model="multiple"
                                    :items="dropdown_button"
                                    :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                    :disabled="disabled"
                                    item-text="title"
                                    item-value="key"
                                    solo
                            ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>

                        <v-flex xs6 d-flex>

                            <v-text-field
                                    v-model="search"
                                    label="Pretraga po imenu, emailu, ulici, gradu"
                                    @keyup.enter="searchCarts()"
                                    class="mr-4"
                            ></v-text-field>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5 mr-2" icon color="primary" v-on="on" @click="searchCarts()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.search, 'Search') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5" icon color="green" v-on="on" @click="exportSearch()"
                                           :loading="loadingExport">
                                        <v-icon>mdi-application-export</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.export, 'Export') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5" icon color="error" v-on="on" @click="resetSearch()">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.reset, 'Reset') }}</span>
                            </v-tooltip>

                        </v-flex>

                    </v-card-title>

                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                        <template v-slot:item.total="{ item }">
                            {{ item.total }} RSD
                        </template>

                        <template v-slot:item.is_visible="{ item }">
                            <v-icon>{{ isVisible(item.is_visible) }}</v-icon>
                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom v-if="checkGuard('cart-edit')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="primary" :to="'/carts/' + item.id + '/edit'"
                                           v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="error" @click="destroy(item)"
                                           v-if="checkGuard('cart-delete')" v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.delete, 'Delete') }}</span>
                            </v-tooltip>
                        </template>

                    </v-data-table>
                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';

    export default {

        data() {
            return {
                headers: [
                    {text: 'ORDER ID', align: 'left', value: 'order_number'},
                    {text: 'CREATED_AT', align: 'left', value: 'created_at'},
                    {text: 'NAME', align: 'left', value: 'address.name'},
                    {text: 'STREET', align: 'left', value: 'address.street'},
                    {text: 'CITY', align: 'left', value: 'address.city'},
                    {text: 'PRICE', align: 'left', value: 'total'},
                    {text: 'SHIPPING', align: 'left', value: 'shipping'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ],
                search: '',
                model: 'Cart',
                showSelect: false,
            };
        },

        mixins: [MultipleSelect,],

        computed: {
            items() {
                return this.$store.getters['cart/getCarts'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.carts, 'Carts'),
                        disabled: true,
                    },
                ];
            },
            loadingExport() {
                return this.$store.getters['common/getLoadingExport'];
            },
        },

        mounted() {
            this.translate();
        },

        methods: {
            async getCarts() {
                this.pagination.cart = this.search === '' ? undefined : this.search;
                await this.$store.dispatch('cart/index', this.pagination);
            },
            async destroy(row) {
                let vuetify = this.$vuetify;
                await this.$store.dispatch('cart/destroy', {...row, vuetify});
            },
            translate() {
                this.headers[0].text = this.user.profile.translations.order_id.toUpperCase();
                this.headers[1].text = this.user.profile.translations.created.toUpperCase();
                this.headers[2].text = this.user.profile.translations.name_surname.toUpperCase();
                this.headers[3].text = this.user.profile.translations.address.toUpperCase();
                this.headers[4].text = this.user.profile.translations.zipcode_town.toUpperCase();
                this.headers[5].text = this.user.profile.translations.price.toUpperCase();
                this.headers[6].text = this.user.profile.translations.shipping.toUpperCase();
                this.headers[7].text = this.user.profile.translations.action.toUpperCase();
            },
            async searchCarts() {
                this.pagination.cart = this.search;
                this.pagination.page = 1;
                await this.getCarts();
            },
            async resetSearch() {
                this.search = '';
                this.pagination.cart = undefined;
                this.pagination.page = 1;
                await this.getCarts();
            },
            async exportSearch() {
                await this.$store.dispatch('cart/exports', this.pagination);
            },
        },

        watch: {
            pagination: {
                handler() {
                    this.getCarts();
                },
                deep: true
            },
        },

        async beforeDestroy() {
            await this.$store.dispatch('cart/clearAll');
        },
    }
</script>
