/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import 'cropperjs/dist/cropper.css';

import Vuetify,
{
    VLayout,
    VApp,
    VContent,
    VContainer,
    VFlex,
    VCard,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VCardText,
    VForm,
    VTextField,
    VCardActions,
    VBtn,
    VFooter,
    VAppBar,
    VAppBarNavIcon,
    VMenu,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
    VListItemAction,
    VListItemContent,
    VNavigationDrawer,
    VListItemAvatar,
    VImg,
    VDivider,
    VListItemIcon,
    VListGroup,
    VSystemBar,
    VListItemGroup,
    VRow,
    VCol,
    VTooltip,
    VBreadcrumbs,
    VCardTitle,
    VSelect,
    VDataTable,
    VSimpleTable,
    VFabTransition,
    VCombobox,
    VCheckbox,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VSnackbar,
    VFileInput,
    VDialog,
    VDatePicker,
    VTimePicker,
    VTextarea,
    VProgressCircular,
} from 'vuetify/lib';

import {abilitiesPlugin} from '@casl/vue';
import defineAbilitiesFor from './packages/ability/Ability';
import {Can} from '@casl/vue';
import {store} from './store/store';
import router from './router';
import Auth from './packages/auth/Auth';
import filters from './filters';
import {interceptions} from './packages/interceptors.js';
import VueRepeater from 'vue-repeater'
import 'vue-repeater/dist/app/css/app.css'

Vue.use(Vuetify, {
    theme: {
        primary: '#805372', // nav
        secondary: '#F9A31A',
        accent: '#CFD8DC',
        error: '#F44336',
        warning: '#3f4a59',
        borde: '#d6d6d6'
    },
    components: {
        VLayout,
        VApp,
        VContent,
        VContainer,
        VFlex,
        VCard,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VCardText,
        VForm,
        VTextField,
        VCardActions,
        VBtn,
        VFooter,
        VAppBar,
        VAppBarNavIcon,
        VMenu,
        VIcon,
        VList,
        VListItem,
        VListItemTitle,
        VListItemAction,
        VListItemContent,
        VNavigationDrawer,
        VListItemAvatar,
        VImg,
        VDivider,
        VListItemIcon,
        VListGroup,
        VSystemBar,
        VListItemGroup,
        VRow,
        VCol,
        VTooltip,
        VBreadcrumbs,
        VCardTitle,
        VSelect,
        VDataTable,
        VSimpleTable,
        VFabTransition,
        VCombobox,
        VCheckbox,
        VTabs,
        VTab,
        VTabsItems,
        VTabItem,
        VSnackbar,
        VFileInput,
        VDialog,
        VDatePicker,
        VTimePicker,
        VTextarea,
        VProgressCircular,
        VueRepeater
    },
});
Vue.use(Auth);

Vue.use(abilitiesPlugin);
Vue.use(filters);

import 'vuetify/dist/vuetify.min.css';

window.defineAbilitiesFor = defineAbilitiesFor;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('app-vue', require('./layouts/App.vue').default);

Vue.component('login', require('./components/auth/Login.vue').default);
Vue.component('register', require('./components/auth/Register.vue').default);

/**
 * form helpers component
 */
Vue.component('upload-image', require('./components/helper/UploadImageHelper.vue').default);
Vue.component('upload-image-cropper', require('./components/helper/UploadImageCropper.vue').default);
Vue.component('date-time-picker', require('./components/helper/DateTimePicker.vue').default);
Vue.component('textarea-ckeditor', require('./components/helper/Ckeditor.vue').default);

axios.defaults.headers.common['Authorization'] = 'Bearer ' + Vue.auth.getToken();

interceptions();

Vue.prototype.checkTranslation = checkTranslation;
Vue.prototype.dataURLtoFile = dataURLtoFile;

const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify: new Vuetify(),
});
