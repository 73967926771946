<template>

    <v-card width="150">
        <img v-if="image" :src="image" style="width:100%;height:auto">
        <img v-else :src="defaultImage" style="width:100%;height:auto">

        <v-card-title primary-title>
            <div>
                <h4 class="card-title">{{ titleImage }} Meta Image</h4>
                <p class="card-text" style="color: red" v-if="error">{{ error[0] }}</p>
                <p class="card-text" v-if="dimensions">Dimensions: {{ dimensions }}</p>
            </div>
        </v-card-title>

        <v-card-actions>
            <v-btn text color="primary">
                <input id="metaImage" type="file" @change="setUpFileUploader" style="display: none;">
                <input type="button" value="Add Image" onclick="document.getElementById('metaImage').click();" />
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {

        data(){
            return {
                domain: domain,
            }
        },

        props: ['image', 'defaultImage', 'titleImage', 'error', 'dimensions'],

        methods:{
            setUpFileUploader(e){

                let file = e.target.files[0];
                let reader = new FileReader();
                let files = e.target.files;
                if(!files.length){
                    return
                }

                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.$emit('uploadImage', {
                        src: e.target.result,
                        file: file,
                    });
                }
            }
        },
    }
</script>
