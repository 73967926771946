<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>
                <v-card class="pa-4">
                    <form-fields @submitForm="submit($event)"></form-fields>
                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('digital-book-create')">
                <v-btn color="primary" dark fab fixed bottom right to="/digital-books/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-layout>
    </v-container>
</template>
<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data () {
            return {
                fillable: fields,
            }
        },
        components: {
            FormFields,
        },
        mixins: [CheckGuard,],

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            digital_book(){
                return this.$store.getters['digital_book/getDigitalBook'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.digital_book, 'Digital Book'),
                        disabled: false,
                        to: '/digital-books',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },
        mounted(){
            this.clear();
            this.getDigitalBook();
            this.getSchoolClasses();
        },
        methods: {
            async getSchoolClasses(){
                await this.$store.dispatch('school_class/index', {lists: 1, sortBy: ['id'], sortDesc: [0]});
            },
            async getDigitalBook(){
                await this.$store.dispatch('digital_book/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                let data = fillForm(this.fillable, this.digital_book, 'PUT');

                await this.$store.dispatch('digital_book/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('digital_book/clear');
            },
        },
    }
</script>


