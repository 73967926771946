<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields
        },

        computed: {
            document() {
                return this.$store.getters['document/getDocument'];
            },
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.documents, 'Documents'),
                        disabled: false,
                        to: '/documents',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.create, 'Create'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted() {
            this.clear();
            await this.getGroups();
        },

        methods: {
            async getGroups() {
                await this.$store.dispatch('group/index', {
                    sortBy: ['id'],
                    sortDesc: [1],
                    lists: true
                });
            },
            clear() {
                this.$store.dispatch('document/clear');
            },
            async submit() {
                this.$store.dispatch('common/changeErrorMessages', [], {root: true});
                let data = fillForm(this.fillable, this.document);
                await this.$store.dispatch('document/store', data);
            },
        },
    }
</script>
