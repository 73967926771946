export const index = (state, documents) => {
    state.documents = documents;
};

export const show = (state, document) => {
    state.document = document;
};

export const clear = state => {
    state.document = {};
};

export const clearAll = state => {
    state.documents = [];
};