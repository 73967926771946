<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">
                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                        <template slot="items" slot-scope="props">
                            <td>{{ props.item.id }}</td>
                            <td class="text-xs-left">{{ props.item.title }}</td>
                            <td class="text-xs-left">{{ props.item.locale }}</td>
                            <td class="text-xs-left">{{ props.item.is_visible? 'Yes' : 'No' }}</td>
                            <td class="text-xs-left">{{ props.item.created_at }}</td>
                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom v-if="checkGuard('language-edit')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="primary" :to="'/dictionaries/' + item.id + '/edit'" v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</span>
                            </v-tooltip>
                        </template>

                    </v-data-table>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';

    export default {
        data() {
            return {
                headers: [
                    {text: 'ID', align: 'left', sortable: true, value: 'id'},
                    {text: 'TITLE', align: 'left', sortable: true, value: 'title'},
                    {text: 'LOCALE', align: 'left', sortable: true, value: 'locale'},
                    {text: 'IS_VISIBLE', align: 'left', sortable: true, value: 'is_visible'},
                    {text: 'CREATED AT',sortable: false,  value: 'created_at'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ],
                model: 'Dictionary',
                showSelect: false,
            }
        },

        mixins: [MultipleSelect,],

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            items(){
                return this.$store.getters['language/getLanguages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.dictionaries, 'Dictionaries'),
                        disabled: true,
                    },
                ];
            },
        },

        methods: {
            async getLanguages() {
                await this.$store.dispatch('language/index', this.pagination);
            },
        },

        watch: {
            pagination: {
                async handler () {
                    await this.getLanguages();
                },
                deep: true
            }
        },
    }
</script>
