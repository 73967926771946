export const index = (state, banners) => {
    state.banners = banners;
};

export const show = (state, banner) => {
    state.banner = banner;
};

export const clear = state => {
    state.banner = {
        remove_image: false,
    };
};

export const clearAll = state => {
    state.banners = [];
};
