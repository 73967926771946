export const index = (state, digitalBooks) => {
    state.digitalBooks = digitalBooks;
};

export const show = (state, digitalBook) => {
    state.digitalBook = digitalBook;
};

export const clear = state => {
    state.digitalBook = {};
};

export const clearAll = state => {
    state.digitalBooks = [];
};
