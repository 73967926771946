<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>
                <v-card class="pa-4 mb-4" v-if="school">
                    <h4>{{ checkTranslation(user.profile.translations.school_inforamtion, 'School Informations') }}</h4>
                    <ul class="list">
                        <li><b>{{ checkTranslation(user.profile.translations.name, 'Name') }}</b>: {{ school.name }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.address, 'Address') }}</b>: {{ school.address }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.post_number, 'Post Number') }}</b>: {{ school.post_number }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.email, 'Email') }}</b>: {{ school.email }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.url, 'Email') }}</b>: {{ school.url }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.identification_number, 'Identification Number') }}</b>: {{ school.identification_number }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.pib, 'PIB') }}</b>: {{ school.pib }}</li>
                    </ul>
                </v-card>
                <v-card class="pa-4 mb-4">
                    <form @submitForm="submit($event)" ref="my_input">
                        <vue-repeater
                            v-model="fields"
                        ></vue-repeater>
                        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">Submit</v-btn>
                    </form>
                </v-card>
                <v-card
                    v-if="items"
                    v-for="receipt in items"
                    :key="receipt.number"
                    class="pa-4 mb-4">
                    <div class="mb-8">
                        <h6>{{ receipt.created_at }}</h6>
                        <div
                            class="mb-8"
                            v-for="(codes, bookName) in receipt.codes"
                        >
                            <h6>{{ bookName }}</h6>
                            <v-simple-table dense>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        {{ checkTranslation(user.profile.translations.code, 'Code') }}
                                    </th>
                                    <th class="text-center">
                                        {{ checkTranslation(user.profile.translations.used_count, 'Used Count') }}
                                    </th>
                                    <!--                                        <th class="text-center">-->
                                    <!--                                            {{ checkTranslation(user.profile.translations.actions, 'Actions') }}-->
                                    <!--                                        </th>-->
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="code in codes"
                                    :key="code.code"
                                >
                                    <td >{{ code.code }}</td>
                                    <td class="text-center">{{ code.used_count }}</td>
                                    <!--                                        <td class="text-center">-->
                                    <!--                                            <v-btn-->
                                    <!--                                                class="mr-2"-->
                                    <!--                                                text-->
                                    <!--                                                icon-->
                                    <!--                                                color="error"-->
                                    <!--                                                @click="destroy(code)"-->
                                    <!--                                                v-if="checkGuard('digital-book-code-delete')"-->
                                    <!--                                                >-->
                                    <!--                                                    <v-icon>mdi-delete</v-icon>-->
                                    <!--                                            </v-btn>-->
                                    <!--                                        </td>-->
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <v-card
                                elevation="2"
                            ></v-card>
                        </div>
                        <v-card-actions class="justify-center">
<!--                            <v-btn-->
<!--                                class="mb-2"-->
<!--                                :color="'primary'"-->
<!--                                @click="addCode(bookName, date)"-->
<!--                            >-->
<!--                                <v-icon>mdi-plus</v-icon>-->
<!--                            </v-btn>-->
                            <v-btn
                                class="mb-2"
                                :color="'secondary'"
                                @click="showPdf(receipt.id)"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                            <v-btn
                                class="mb-2"
                                :color="'pink'"
                                @click="downloadPdf(receipt.id)"
                            >
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-flex>
            <v-layout column class="fab-container" v-if="checkGuard('digital-book-code-create')">
<!--                <v-btn-->
<!--                    class="mb-2"-->
<!--                    :color="'secondary'"-->
<!--                    fab-->
<!--                    @click="showPdf"-->
<!--                >-->
<!--                    <v-icon>mdi-printer</v-icon>-->
<!--                </v-btn>-->
<!--                <v-btn-->
<!--                    class="mb-2"-->
<!--                    :color="'pink'"-->
<!--                    fab-->
<!--                    @click="downloadExcel"-->
<!--                    >-->
<!--                    <v-icon>mdi-table-edit</v-icon>-->
<!--                </v-btn>-->
            </v-layout>
        </v-layout>
    </v-container>
</template>
<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';

    export default {
        data() {
            return {
                fields: [
                    {
                        name: FormFields,
                        value: {
                        }
                    }
                ],
                collectionArr: {
                    codes: []
                },
                items: []
            };
        },
        mixins: [CheckGuard,],
        components: {
            FormFields,
        },
        created() {
          this.getCodes();
          this.getDigitalBooks();
        },
        computed: {
            breadcrumbs() {
                return [
                    {
                        text: 'Home',
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Digital Book Codes',
                        disabled: true,
                    },
                ];
            },
            school() {
                return this.$store.getters['school/getSchool'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
        },
        methods: {
            async getCodes() {
                axios.get(`api/digital-book/codes?school_id=${this.$route.query.school_id}`)
                    .then(response => {
                        this.items = response.data.data
                        console.log(this.items);
                    });
            },
            async getSchool(){
                await this.$store.dispatch('school/show', this.$route.query.school_id);
            },
            async getDigitalBooks(){
                await this.$store.dispatch('digital_book/index', {lists: 1, sortBy: ['id'], sortDesc: [0]});
            },

            async submit(data) {
                this.fields.forEach((data, index) => {
                    let codeCollection = {
                        digital_book_id: '',
                        code_count: '',
                        school_id: ''
                    };
                    codeCollection.digital_book_id = data.value.digital_book_id;
                    codeCollection.code_count = data.value.code_count;
                    codeCollection.school_id = this.$route.query.school_id;

                    this.collectionArr.codes.push(codeCollection);
                });

                this.$store.dispatch('common/changeErrorMessages', [], {root: true});
                await this.$store.dispatch('digital_book_code/store', this.collectionArr);
            },
            async destroy(row){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('digital_book_code/destroy', [row, vuetify]);
            },
            async showPdf(receiptId) {
                await this.$store.dispatch('digital_book_code/showPdf', receiptId);
            },

            // async addCode(bookName, createdAt) {
            //     let params = {
            //         school_id: this.$route.query.school_id,
            //         book_name: bookName,
            //         created_at: createdAt,
            //     };
            //
            //     await this.$store.dispatch('digital_book_code/addCode', params);
            // },

            async downloadPdf(receiptId) {
                await this.$store.dispatch('digital_book_code/downloadPdf', receiptId);
            },


            // async downloadExcel() {
            //     await this.$store.dispatch('digital_book_code/exports', this.$route.query.school_id);
            // }
        },
        async mounted(){
            await this.getSchool();
        },
    }
</script>

<style>
    .fab-container {
        position: fixed;
        bottom: 40px;
        right: 25px;
    }
</style>
