import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user/index.js';
import search from './modules/search';
import nav from './modules/nav';
import ability from './modules/ability';
import language from './modules/language';
import role from './modules/role';
import permission_group from './modules/permission-group';
import permission from './modules/permission';
import common from './modules/common';
import profile from './modules/profile';
import auth from './modules/auth';
import multiple from './modules/multiple';
import dictionary from './modules/dictionary';
import delivery from './modules/delivery';
import category_news from './modules/category-news';
import discount_type from './modules/discount-type';
import discount from './modules/discount';
import promo_code from './modules/promo-code';
import post from './modules/post';
import category from './modules/category';
import book from './modules/book';
import gallery from './modules/gallery';
import author from './modules/author';
import box from './modules/box';
import cart from './modules/cart';
import city from './modules/city';
import address from './modules/address';
import menu from './modules/menu';
import page from './modules/page';
import link from './modules/link';
import group from './modules/group';
import document from './modules/document';
import status from './modules/status';
import banner from './modules/banner';
import tag from './modules/tag';
import newsletter from './modules/newsletter';
import school from './modules/school';
import school_class from './modules/school-class';
import digital_book from './modules/digital-book';
import digital_book_code from './modules/digital-book-code';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        common,
        user,
        search,
        nav,
        ability,
        language,
        role,
        permission_group,
        permission,
        profile,
        auth,
        multiple,
        dictionary,
        category_news,
        post,
        category,
        delivery,
        book,
        gallery,
        author,
        box,
        cart,
        city,
        address,
        menu,
        link,
        page,
        group,
        document,
        status,
        discount_type,
        discount,
        promo_code,
        banner,
        tag,
        newsletter,
        school,
        school_class,
        digital_book,
        digital_book_code
    },
});
