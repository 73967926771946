<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <v-card-title>

                        <v-flex xs4 d-flex v-if="showSelect">
                            <v-select
                                    @change="multipleChange()"
                                    v-model="multiple"
                                    :items="dropdown_button"
                                    :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                    :disabled="disabled"
                                    item-text="title"
                                    item-value="key"
                                    solo
                            ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>

                        <v-flex xs6 d-flex>

                            <v-text-field
                                    v-model="search"
                                    label="Pretraga po imenu, emailu, gradu"
                                    @keyup.enter="searchAddress()"
                                    class="mr-4"
                            ></v-text-field>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5 mr-2" icon color="primary" v-on="on" @click="searchAddress()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.search, 'Search') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5" icon color="green" v-on="on" @click="exportSearch()"
                                           :loading="loadingExport">
                                        <v-icon>mdi-application-export</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.export, 'Export') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mt-5" icon color="error" v-on="on" @click="resetSearch()">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.reset, 'Reset') }}</span>
                            </v-tooltip>

                        </v-flex>

                    </v-card-title>

                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                    </v-data-table>
                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';

    export default {
        data() {
            return {
                model: 'Address',
                search: '',
                showSelect: false,
            };
        },

        mixins: [MultipleSelect,],

        computed: {
            items() {
                return this.$store.getters['address/getAddresses'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.addresses, 'Addresse'),
                        disabled: true,
                    },
                ];
            },
            headers() {
                return [
                    {text: 'ID', align: 'left', value: 'id'},
                    {text: 'NAME', align: 'left', value: 'name'},
                    {text: 'STREET', align: 'left', value: 'street'},
                    {text: 'PHONE', align: 'left', value: 'phone'},
                    {text: 'EMAIL', align: 'left', value: 'email'},
                    {text: 'COUNTRY', align: 'left', value: 'country'},
                    {text: 'CITY', align: 'left', value: 'city'},
                    {text: 'ZIP', align: 'left', value: 'zipcode'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ];
            },
            loadingExport() {
                return this.$store.getters['common/getLoadingExport'];
            },
        },

        mounted() {
            this.translate();
        },

        methods: {
            async getAddresses() {
                this.pagination.address = this.search === '' ? undefined : this.search;
                await this.$store.dispatch('address/index', this.pagination);
            },
            async destroy(row) {
                let vuetify = this.$vuetify;
                await this.$store.dispatch('address/destroy', {...row, vuetify});
            },
            translate() {
                this.headers[1].text = this.user.profile.translations.name.toUpperCase();
                this.headers[2].text = this.user.profile.translations.street.toUpperCase();
                this.headers[3].text = this.user.profile.translations.phone.toUpperCase();
                this.headers[4].text = this.user.profile.translations.email.toUpperCase();
                this.headers[5].text = this.user.profile.translations.country.toUpperCase();
                this.headers[6].text = this.user.profile.translations.city.toUpperCase();
                this.headers[7].text = this.user.profile.translations.zip.toUpperCase();
                this.headers[8].text = this.user.profile.translations.action.toUpperCase();
            },
            async searchAddress() {
                this.pagination.address = this.search;
                this.pagination.page = 1;
                await this.getAddresses();
            },
            async resetSearch() {
                this.search = '';
                this.pagination.address = undefined;
                this.pagination.page = 1;
                await this.getAddresses();
            },
            async exportSearch() {
                await this.$store.dispatch('address/exports', this.pagination);
            },
        },
        watch: {
            pagination: {
                handler() {
                    this.getAddresses();
                },
                deep: true
            },
        },

        async beforeDestroy() {
            await this.$store.dispatch('address/clearAll');
        },
    }
</script>