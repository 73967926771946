<template>
    <form v-if="school">
        <v-text-field
                v-model="school.name"
                :error-messages="error_messages.name"
                :label="checkTranslation(user.profile.translations.name, 'Name')"
                required
        ></v-text-field>
        <v-text-field
                v-model="school.address"
                :error-messages="error_messages.address"
                :label="checkTranslation(user.profile.translations.address, 'Address')"
                :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.post_number"
            :error-messages="error_messages.post_number"
            :label="checkTranslation(user.profile.translations.post_number, 'Post Number')"
            :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.email"
            :error-messages="error_messages.email"
            :label="checkTranslation(user.profile.translations.email, 'Email')"
            :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.phone"
            :error-messages="error_messages.phone"
            :label="checkTranslation(user.profile.translations.phone, 'Phone')"
            :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.url"
            :error-messages="error_messages.url"
            :label="checkTranslation(user.profile.translations.url, 'Url')"
            :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.identification_number"
            :error-messages="error_messages.identification_number"
            :label="checkTranslation(user.profile.translations.identification_number, 'Identification Number')"
            :required="false"
        ></v-text-field>
        <v-text-field
            v-model="school.pib"
            :error-messages="error_messages.pib"
            :label="checkTranslation(user.profile.translations.pib, 'PIB')"
            :required="false"
        ></v-text-field>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            school() {
                return this.$store.getters['school/getSchool'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
            // templateTypes() {
            //     return this.$store.getters['tag/getTemplateTypes'];
            // },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('school/clear');
            },
        },
    }
</script>
