export default {
    tags: [],
    tag: {},
    templateTypes: [
        {
            name : 'Book Template',
            value: 'book_template',
        },
        {
            name : 'Top Template',
            value: 'top_template',
        },
    ],
}
