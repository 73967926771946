export const index = (state, menus) => {
    state.menus = menus;
};

export const show = (state, menu) => {
    state.menu = menu;
};

export const index_sort = (state, links_sort) => {
    state.links_sort = links_sort;
};

export const clear = state => {
    state.menu = {};
};

export const clearAll = state => {
    state.menus = [];
};