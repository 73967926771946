export const index = (state, books) => {
    state.books = books;
};

export const show = (state, book) => {
    state.book = book;
};

export const clear = state => {
    state.book = {
        remove_image: false,

    };
};

export const clearAll = state => {
    state.books = [];
};
