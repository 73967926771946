export const index = (state, permissions) => {
    state.permissions = permissions;
};

export const show = (state, permission) => {
    state.permission = permission;
};

export const permission_ids = (state, permission_ids) => {
    state.permission_ids = permission_ids;
};

export const clear = state => {
    state.permission = {
        'permission_group_id': '',
        'name': '',
        'guard_name': '',
        'is_visible': false,
    };
};

export const clearAll = state => {
    state.permissions = [];
};
