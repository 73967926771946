<template>
    <form v-if="document">
        <v-select
                v-if="groups"
                v-model="document.group_id"
                :items="groups"
                :label="checkTranslation(user.profile.translations.group, 'Group')"
                :error-messages="error_messages.group_id"
                clearable
                item-text="title"
                item-value="id"
        ></v-select>
        <v-text-field
                v-model="document.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>
        <span v-if="edit">
              <v-icon v-ifile  @click="openLink(document.file)">mdi-clipboard-file</v-icon>
              {{ document.title }}
            <v-icon @click="destroy(document)">mdi-minus</v-icon>
        </span>
        <v-file-input v-else
                v-model="document.file"
                :error-messages="error_messages.file"
                :label="checkTranslation(user.profile.translations.file, 'File')"
        ></v-file-input>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="document.is_visible"
                color="primary"
        ></v-checkbox>
        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    import router from '../../router';
    export default {
        props: ['edit'],
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            groups() {
                return this.$store.getters['group/getGroups'];
            },
            document() {
                return this.$store.getters['document/getDocument'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('document/clear');
            },
            openLink(link){
                window.open(link,'_blank')  ;
            },
            async destroy(row){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('document/destroy', { ...row, vuetify });
                router.push('/documents');
            },
        },

    }
</script>

