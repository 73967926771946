<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md8 lg6>

                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Återställ lösenord</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-form>
                            <v-text-field label="e-post"
                                          required
                                          v-model="data.email"
                                          :error="error_messages.email == false"
                                          :error-messages="error_messages.email"
                                          @keyup.enter="submit()">
                            </v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="submit()">Skicka epost</v-btn>
                    </v-card-actions>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import swal from 'sweetalert2'

    export default {
        data() {
            return {
                fillable: ['email'],
                data: {},
                error_messages: [],
            };
        },

        methods: {
            async submit(){
                let data = fillForm(this.fillable, this.data);

                await axios.post('api/forgotten-password', data).then(res => {
                    swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Framgång',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.$router.push('/login');
                }).catch( e => {
                    this.error_messages = e.response.data.errors;
                    console.log(this.error_messages);
                });

                this.error_messages = [];
            }
        }
    }
</script>