import swal from 'sweetalert2';
import router from '../../../router';
import vuetifyPagination from '../../../packages/vuetifyPagination';

/**
 * method used to get status from Backend and store in Vuex
 *
 * @param {object} context
 * @param {object} pagination
 */
export const index = async (context, pagination) => {
    context.dispatch('common/changePagination', pagination, {root: true});
    
    const {lists} = pagination;

    await axios.get(`api/newsletters?${vuetifyPagination(pagination)}`)
        .then(res => {
            context.commit('index', res.data.data);
            console.log(res.data.data);
            if (!lists) {
                context.dispatch('common/changeTotal', res.data.meta.total, {root: true});
            }
        }).catch(e => {
            // console.log(e.response);
            // context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};