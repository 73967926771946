import swal from 'sweetalert2';
import router from '../../../router';
import vuetifyPagination from '../../../packages/vuetifyPagination';

/**
 * method used to get digital-book-codes from Backend and store in Vuex
 *
 * @param {object} context
 * @param {object} pagination
 */
export const index = async (context, pagination) => {
    context.dispatch('common/changePagination', pagination, {root: true});

    const {lists} = pagination;

    await axios.get(`api/digital-book/codes?school_id=${pagination.school_id}&${vuetifyPagination(pagination)}`)
        .then(res => {
            context.commit('index', res.data.data);
            if (!lists) {
                context.dispatch('common/changeTotal', res.data.meta.total, {root: true});
            }
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to store digital book item to the Backend and and store in Vuex
 *
 * @param {object} context
 * @param {object} callback - digital-book item
 */
export const store = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/digital-book/codes', callback)
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            window.location.reload();
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};


export const addCode = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];
    await axios.post('api/digital-book/code', callback)
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            window.location.reload();
        })
        .catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to remove digital-book item from Backend and store in Vuex
 */
export const destroy = async (context, callback) => {
    await swal.fire({
        title: 'Da li si siguran!',
        text: 'Nećete moći da povratite radnju!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'secondary',
        confirmButtonText: 'Yes delete it!',
        cancelButtonText: 'Cancel',
    }).then(async (result) => {
        if (result.value) {
            await axios.delete('api/digital-book/codes/' + callback[0].id)
                .then(res => {
                    swal.fire(
                        'Deleted',
                        'Your file has been deleted!',
                        'success',
                    );
                    location.reload();
                })
                .catch(e => {
                    console.log(e);
                    context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
                });
        }
    });
};

export const showPdf = async (context, receiptId) => {
    context.dispatch('common/changeLoadingExport', true, {root: true});
    window.open(`digital-book/pdf/view?receipt_id=${receiptId}`, '_blank');
};

export const downloadPdf = async (context, receiptId) => {
    context.dispatch('common/changeLoadingExport', true, {root: true});

    await axios.get(
        `digital-book/pdf/view/download?receipt_id=${receiptId}`,
        { responseType: 'blob' }
    )
        .then(res => {
            let fileName = res.headers["content-disposition"].split(
                "filename="
            )[1];

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            fileName = String(fileName);
            link.setAttribute('download', fileName.replace(/['"]+/g, ''));
            document.body.appendChild(link);
            link.click();
        })
        .catch(e => {
            console.log(e);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

// export const exports = async (context, schoolId) => {
//     context.dispatch('common/changeLoadingExport', true, {root: true});
//
//     await axios.get(`api/digital-book/excel/export?school_id=${schoolId}`)
//         .then(res => {
//             context.dispatch('common/changeLoadingExport', false, {root: true});
//             console.log(res.data.data);
//             window.open(res.data.data);
//         })
//         .catch(e => {
//             console.log(e);
//             context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
//         });
// };

/**
 * method used to clear digital-books properties and error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.commit('clear');
    context.dispatch('common/changeErrorMessages', [], {root: true});
};

/**
 * method used to clear authors properties
 *
 * @param {object} context
 */
export const clearAll = async context => {
    await context.commit('clearAll');
};

