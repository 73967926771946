<template>
    <v-app id="inspire">

        <left-nav></left-nav>

        <v-app-bar
                app
                clipped-left
                v-model="top_drawer"
        >
            <v-app-bar-nav-icon @click.stop="nav()" />
            <v-toolbar-title>Škola Plus</v-toolbar-title>

            <v-spacer></v-spacer>

            <!--<v-btn icon>-->
                <!--<v-icon>mdi-heart</v-icon>-->
            <!--</v-btn>-->

            <!--<v-btn icon>-->
                <!--<v-icon>mdi-magnify</v-icon>-->
            <!--</v-btn>-->

            <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">

                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>


                <v-list class="pa-0">

                    <v-list-item to="/profile" ripple="ripple" rel="noopener">

                        <v-list-item-action>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>Profile</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item @click.prevent="logout()" ripple="ripple" rel="noopener">

                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>Log Out</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>


            </v-menu>
        </v-app-bar>

        <v-content>
            <v-container
                    class="fill-height"
                    fluid
            >

                <router-view></router-view>

            </v-container>
        </v-content>

        <v-footer app>
            <span>GENERA &copy; {{ year }}</span>
        </v-footer>

        <!--<loader></loader>-->

    </v-app>
</template>

<script>
    import {theme_light_colors, theme_dark_colors} from '../colors';
    import LeftNav from '../layouts/partials/LeftBar.vue';
    import Loader from '../components/helper/Loader.vue';

    export default {
        data(){
            return {

            };
        },

        components: {
            'left-nav': LeftNav,
            'loader': Loader,
        },

        computed: {
            top_drawer() {
                return this.$store.getters['nav/getShowTopBar'] || false;
            },
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            year() {
                return new Date().getYear() + 1900;
            },
        },
        mounted() {
            // Echo.channel('push.channel')
            //     .listen('.push.event', (e) => {
            //         this.snackbar = true;
            //         this.notification = e.text;
            //     });
            // Echo.channel('chat.channel')
            //     .listen('.chat.event', (e) => {
            //         console.log('chat.event');
            //         if(e.data.user.id !== this.user.id){
            //             this.$store.commit('chat/update', e.data);
            //         }
            //     });
        },
        methods: {
            nav() {
                this.$store.dispatch('nav/changeShowLeftBar');
            },
            navRight() {
                this.$store.dispatch('nav/changeShowRightBar');
            },
            logout() {
                this.$store.dispatch('nav/setShowLeftBar', false);
                this.$store.dispatch('nav/setShowTopBar', false);
                this.$store.dispatch('nav/setShowRightBar', false);
                this.$router.push('/logout');
            }
        },
        watch: {
            user() {
                if (this.user != null) {
                    // this.dark = this.user.profile.dark_theme ? true : false;
                }
            },
            dark() {
                this.$store.dispatch('user/changeDarkTheme', this.dark);
                this.$vuetify.theme = this.dark ? theme_dark_colors : theme_light_colors;
            }
        },
    }
</script>
