export const index = (state, cities) => {
    state.cities = cities;
};

export const show = (state, city) => {
    state.city = city;
};

export const clear = state => {
    state.city = {};
};

export const clearAll = state => {
    state.cities = [];
};