export const getUsers = (state) => {
    return state.users;
};

export const getUser = (state) => {
    return state.user;
};

export const getUserProfile = (state) => {
    return state.user_profile;
};
