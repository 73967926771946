<template>
    <v-card>
        <v-container fluid>
            <v-row>
                <v-col
                        v-for="(image, index) in images"
                        :key="image.id"
                        class="d-flex child-flex"
                        cols="4"
                >
                    <v-card flat tile class="d-flex">
                        <v-img
                                :src="image.path"
                                :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`"
                                aspect-ratio="1"
                                class="grey lighten-2"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <v-icon class="remove-img" color="error" @click="removeImage(image)">mdi-minus</v-icon>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</template>

<script>
    export default {
        computed: {
            images(){
                return this.$store.getters['gallery/getImages'];
            },
        },
        methods: {
            async removeImage(image){
                let vuetify = this.$vuetify;
                await this.$store.dispatch('gallery/destroy', [this.$route.params.slug, {...image, vuetify}]);
            }
        },

    }
</script>

<style>
    .remove-img{
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
</style>
