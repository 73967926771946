<template>

    <v-container fluid fill-height>
        <v-layout>

            <v-flex>

                <v-card class="mb-4">

                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>

                </v-card>

                <v-card class="pa-4">

                    <v-card-title>

                        <v-flex xs6 sm4 d-flex v-if="showSelect">
                            <v-select
                                    @change="multipleChange()"
                                    v-model="multiple"
                                    :items="dropdown_button"
                                    :label="checkTranslation(user.profile.translations.choose, 'Choose')"
                                    :disabled="disabled"
                                    item-text="title"
                                    item-value="key"
                                    solo
                            ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>

                    </v-card-title>

                    <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :options.sync="pagination"
                            :server-items-length="total_items"
                            :loading="loading"
                            :items-per-page="50"
                            class="elevation-1"
                            :footer-props="{
                              itemsPerPageOptions: [50,25,10],
                              itemsPerPageText: checkTranslation(user.profile.translations.rows_per_page, 'Rows per page'),
                            }"
                            :show-select="showSelect"
                    >

                        <template v-slot:item.roles="{ item }">
                            [<span v-for="role in item.roles">{{ role.name }}</span>,]
                        </template>

                        <template v-slot:item.created_at="{ item }">
                            {{ setDateTime(item.created_at) }}
                        </template>

                        <template v-slot:item.block="{ item }">
                            <v-icon>{{ isVisible(!item.block) }}</v-icon>
                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom v-if="checkGuard('user-edit') && item.email_verified_at == null">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="primary" v-on="on" @click="resendVerifyEmail(item)">
                                        <v-icon>mdi-email</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.resend_verify_email, 'Resend verification email') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="checkGuard('user-edit')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="primary" :to="'/users/' + item.id + '/edit'" v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-2" text icon color="error" @click="destroy(item)" v-if="checkGuard('user-delete')" v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ checkTranslation(user.profile.translations.delete, 'Delete') }}</span>
                            </v-tooltip>
                        </template>

                    </v-data-table>

                </v-card>

            </v-flex>

            <v-fab-transition v-if="checkGuard('user-create')">

                <v-btn :color="'primary'" dark fab fixed bottom right to="/users/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

            </v-fab-transition>


        </v-layout>
    </v-container>

</template>

<script>
    import MultipleSelect from '../../mixins/MultipleSelect.js';
    import moment from 'moment';

    export default {

        data() {
            return {
                headers: [
                    {text: 'ID', align: 'left', sortable: true, value: 'id'},
                    {text: 'USERNAME', align: 'left', sortable: true, value: 'username'},
                    {text: 'EMAIL', sortable: true, value: 'email'},
                    {text: 'ROLES', sortable: false, value: 'roles'},
                    {text: 'BLOCK', sortable: true, value: 'block'},
                    {text: 'CREATED AT', sortable: false, value: 'created_at'},
                    {text: 'ACTION', sortable: false, align: 'right', value: 'action'},
                ],
                model: 'User',
                showSelect: false,
            };
        },

        mixins: [MultipleSelect,],

        computed: {
            items() {
                return this.$store.getters['user/getUsers'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.users, 'Users'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted() {
            this.translate();
        },

        methods: {
            async getUsers() {
                await this.$store.dispatch('user/index', this.pagination);
            },
            async destroy(row) {
                let vuetify = this.$vuetify;
                await this.$store.dispatch('user/destroy', {...row, vuetify});
            },
            translate() {
                this.headers[1].text = this.user.profile.translations.name.toUpperCase();
                this.headers[2].text = this.user.profile.translations.email.toUpperCase();
                this.headers[3].text = this.user.profile.translations.roles.toUpperCase();
                this.headers[4].text = this.user.profile.translations.block.toUpperCase();
                this.headers[5].text = this.user.profile.translations.created_at.toUpperCase();
                this.headers[6].text = this.user.profile.translations.action.toUpperCase();
            },
            setDateTime(date) {
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            },
            async resendVerifyEmail(row) {
                let vuetify = this.$vuetify;
                await this.$store.dispatch('auth/resend_verify_email', {...row, vuetify});
            },
        },

        watch: {
            pagination: {
                handler() {
                    this.getUsers();
                },
                deep: true
            },
        },

        async beforeDestroy() {
            await this.$store.dispatch('user/clearAll');
        },
    }
</script>
