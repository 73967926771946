export default {

    namespaced: true,

    state: {
        /** list of permissions **/
        permissionList: [],
    },
    getters: {
        /** show list of permissions **/
        getPermissionList: state => {
            return state.permissionList;
        },
    },
    mutations: {
        /** change list of permissions **/
        changePermissionList: (state, data) => {
            state.permissionList = data;
        },
    },
    actions: {
        /** change list of permissions **/
        changePermissionList: (context, data) => {
            context.commit('changePermissionList', data);
        },
    },
};