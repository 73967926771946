import { store } from '../store/store';
import router from '../router';

export function interceptions() {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        store.dispatch('common/changeLoading', true);
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        store.dispatch('common/changeLoading', false);
        return response
    }, async function (error) {
        store.dispatch('common/changeLoading', false);
        const { config, response: { status } } = error;
        const originalRequest = config;

        if(error.response.data.hint){
            Vue.auth.destroyToken();
            router.push('/logout');
            return;
        }else if (status === 401) {

            if(Vue.auth.getToken()){
                await store.dispatch('auth/refresh_token');

                const retryOriginalRequest = new Promise((resolve) => {
                    originalRequest.headers.Authorization = 'Bearer ' + Vue.auth.getToken();
                    resolve(axios(originalRequest))
                });
                return retryOriginalRequest;
            }
        }
        return Promise.reject(error);
    });

};
