import { router } from './';
import defineAbilitiesFor from "../packages/ability/Ability";
import { store } from "../store/store";

export const beforeEach = (async (to, from, next) => {
    const abilities = defineAbilitiesFor(store.getters['ability/getPermissionList']);

    if(Vue.auth.isAuth() && abilities.rules.length == 0){
        /**
         * router if page is hard reload
         */
        await axios.get('api/profile?cms=1')
            .then(res => {
                if(res.data.data.block == 1){
                    Vue.auth.destroyToken();
                    this.$router.push('/logout');
                }else{
                    store.commit('user/show_profile', res.data.data);
                    store.dispatch('nav/setShowLeftBar', true);
                    store.dispatch('nav/setShowTopBar', true);
                    store.dispatch('ability/changePermissionList', res.data.permissions);

                    defineAbilitiesFor(store.getters['ability/getPermissionList']);

                    if(to.matched.some(record => record.meta.forVisitors)){
                        if(Vue.auth.isAuth()){
                            next({ path: "/home" });
                        } else next();
                    }
                    else if(to.matched.some(record => record.meta.forAuth)){
                        if(!Vue.auth.isAuth()){
                            next({ path: "/login" });
                        } else {
                            if(to.matched.some(record => record.meta.group)){
                                const abilities = defineAbilitiesFor(store.getters['ability/getPermissionList']);
                                const canNavigate = to.matched.some(route => {
                                    return abilities.can(route.meta.group, route.meta.guard_name)
                                });

                                if(!canNavigate) {
                                    next({ path: "/home" });
                                }
                            }

                            next();
                        }
                    }
                    else next();
                }
            })
            .catch(e => {
                // if(e.response.status == 401){
                //     Vue.auth.destroyToken();
                //     this.$router.push('/login');
                // }
            });

    }else{
        /**
         * router if page is not hard reload
         */
        if(to.matched.some(record => record.meta.forVisitors)){
            if(Vue.auth.isAuth()){
                next({ path: "/home" });
            } else next();
        }
        else if(to.matched.some(record => record.meta.forAuth)){
            if(!Vue.auth.isAuth()){
                next({ path: "/login" });
            } else {
                if(to.matched.some(record => record.meta.group)){
                    const canNavigate = to.matched.some(route => {
                        return abilities.can(route.meta.group, route.meta.guard_name)
                    });

                    if(!canNavigate) {
                        next({ path: "/home" });
                    }
                }

                next();
            }
        }
        else next();
    }
});

export default beforeEach;
