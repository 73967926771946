import swal from 'sweetalert2';

/**
 * method used to get image from Backend and store in Vuex
 *
 * @param {object} context
 * @param {int} callback - book_slug
 */
export const index = async (context, callback) => {
    await axios.get(`api/books/${callback}/galleries?lists=1`)
        .then(res => {
            context.commit('index', res.data.data);
        }).catch(e => {
            console.log(e.response);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to get image item from Backend and store in Vuex
 *
 * @param {object} context
 * @param {array} callback[0] - book_slug | callback[1] - gallery_slug
 */
export const show = async (context, callback) => {

    await axios.get('api/books/' + callback[0] + '/galleries/' + callback[1])
        .then(res => {
            context.commit('show', res.data.data);
        })
        .catch(e => {
            console.log(e);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
        });
};

/**
 * method used to remove image item from Backend and store in Vuex
 *
 * @param {object} dispatch
 * @param {object} commit
 * @param {object} getters
 * @param {object} rootGetters
 * @param {array} callback[0] - book_slug | callback[1] - image item
 */
export const destroy = async ({dispatch, commit, getters, rootGetters}, callback) => {
    let user = rootGetters['user/getUserProfile'];
    await swal.fire({
        title: user.profile.translations.are_you_sure,
        text: user.profile.translations.you_wont_be_able_to_revert_this,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: callback[1].vuetify.theme.primary,
        cancelButtonColor: callback[1].vuetify.theme.secondary,
        confirmButtonText: checkTranslation(user.profile.translations.yes_delete_it, 'Yes delete it!'),
        cancelButtonText: checkTranslation(user.profile.translations.cancel, 'Cancel'),
    }).then(async (result) => {
        if (result.value) {
            await axios.delete('api/books/' + callback[0] +  '/galleries/' + callback[1].id)
                .then(res => {
                    swal.fire(
                        checkTranslation(user.profile.translations.deleted, 'Deleted'),
                        checkTranslation(user.profile.translations.your_file_has_been_deleted, 'Your file has been deleted!'),
                        'success',
                    );
                    dispatch('index', callback[0]);
                })
                .catch(e => {
                    console.log(e);
                    context.dispatch('common/changeErrorMessages', e.response.data.errors, {root: true});
                });
        }
    });
};

/**
 * method used to clear category properties and error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.commit('clear');
    context.dispatch('common/changeErrorMessages', [], {root: true});
};

/**
 * method used to clear categories properties
 *
 * @param {object} context
 */
export const clearAll = async context => {
    await context.commit('clearAll');
};
