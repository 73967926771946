export const index = (state, schoolClasses) => {
    state.schoolClasses = schoolClasses;
};

export const show = (state, schoolClass) => {
    state.schoolClass = schoolClass;
};

export const clear = state => {
    state.schoolClass = {};
};

export const clearAll = state => {
    state.schoolClasses = [];
};
