<template>
    <form v-if="post">
        <v-select
                v-if="categories"
                v-model="post.category_news_id"
                :items="categories"
                :label="checkTranslation(user.profile.translations.category, 'Category')"
                :error-messages="error_messages.category_news_id"
                clearable
                item-text="title"
                item-value="id"
        ></v-select>

        <v-select
            v-model="post.type"
            :items="types"
            :label="checkTranslation(user.profile.translations.type, 'Type')"
            :error-messages="error_messages.type"
            clearable
            item-text="name"
            item-value="value"
        ></v-select>

        <v-text-field
                v-model="post.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>

        <v-text-field
            v-model="post.summary"
            :error-messages="error_messages.summary"
            :label="checkTranslation(user.profile.translations.summary, 'Summary')"
            required
        ></v-text-field>

        <textarea-ckeditor
                :label="checkTranslation(user.profile.translations.content, 'Content')"
                :value="post.content"
                :required="false"
                @changeValue="post.content = $event"
        ></textarea-ckeditor>

        <v-text-field
            v-model="post.meta_title"
            :error-messages="error_messages.meta_title"
            :label="checkTranslation(user.profile.translations.meta_title, 'Meta Title')"
            required
        ></v-text-field>

        <v-text-field
            v-model="post.meta_description"
            :error-messages="error_messages.meta_description"
            :label="checkTranslation(user.profile.translations.meta_description, 'Meta Description')"
            required
        ></v-text-field>

        <v-text-field
            v-model="post.meta_og_description"
            :error-messages="error_messages.meta_og_description"
            :label="checkTranslation(user.profile.translations.meta_og_description, 'Meta Social Media Description')"
            required
        ></v-text-field>

        <upload-image
            :model="post"
            :image="post.meta_image"
            :label="checkTranslation(user.profile.translations.meta_image, 'Meta Image')"
            @uploadImage="setMetaImage($event)"
            @removeImage="removeMetaImage()"
        ></upload-image>

        <v-text-field
            v-model="post.video_url"
            :error-messages="error_messages.video_url"
            :label="checkTranslation(user.profile.translations.video_url, 'Video Url')"
            required
        ></v-text-field>

        <upload-image-cropper
                :imgWidth="630"
                :imgHeight="400"
                :model="post"
                :image="post.image"
                @uploadImage="setImage($event)"
                @removeImage="removeImage()"
        ></upload-image-cropper>

        <date-time-picker
                v-model="post.published_at"
                :label="checkTranslation(user.profile.translations.published_at, 'Published_at')"
                :error-messages="error_messages.published_at"
        > </date-time-picker>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="post.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {
        data: function () {
            return {
                types: [
                    {
                        'name' : 'Blog',
                        'value' : 'blog'
                    },
                    {
                        'name' : 'Vesti',
                        'value' : 'news'
                    }
                ]
            }
        },
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            post() {
                return this.$store.getters['post/getPost'];
            },
            categories() {
                return this.$store.getters['category_news/getCategories'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            setImage(image) {
                this.post.image = image.file;
            },
            setMetaImage(image) {
                this.post.meta_image = image.file;
            },
            removeImage(image) {
                this.post.image = null;
                this.post.remove_image = true;
            },
            removeMetaImage(image) {
                this.post.meta_image = null;
                this.post.remove_meta_image = true;
            },
            onUpdate (text) {
                this.text = text
            },
            clear() {
                this.$store.dispatch('post/clear');
            },
        },
    }
</script>
