<template>

    <div>

        <div style="position: relative">
            <!-- Uploader -->
            <input type="file" name="image" id="file" accept="image/*" @change="prepareImage"/>
            <label for="file" class="uploader-wrap">
                <div v-if="current_image">
                    <img :src="current_image" class="cropped-image"/>
                </div>
                <div v-else>
                    <img v-if="my_image" :src="my_image" class="cropped-image"/>
                </div>

                <span v-else class="uploader-icon-wrap">
                <v-icon class="uploader-icon">photo</v-icon>
            </span>
            </label>

            <v-icon class="remove-icon" @click="clearImage" v-if="current_image || my_image">mdi-minus</v-icon>
        </div>
        <!-- Uploader -->

        <!-- Modal Box -->
        <v-dialog v-model="modal" persistent :width="672">
            <v-card>
                <!-- Cropper -->
                <v-card-text>
                    <vue-cropper
                            ref='cropper'
                            :guides="true"
                            :view-mode="0"
                            drag-mode="crop"
                            :auto-crop-area="autoCropArea"
                            :background="true"
                            :rotatable="true"
                            :src="img_src"
                            alt="Source Image"
                            :aspectRatio="imgWidth/imgHeight"
                            :initialAspectRatio="imgWidth/imgHeight"
                            :zoomable="true"
                            :scalable="true"
                            :movable="true"
                            :cropBoxResizable="true"
                            :cropBoxMovable="true"
                            :wheelZoomRatio="0.03"
                            :autoCrop="true"
                            :img-style="{ 'width': '672px', 'height': '474px' }"
                    >
                    </vue-cropper>
                </v-card-text>
                <!-- Cropper -->

                <v-divider></v-divider>

                <v-card-text v-if="show_preview">
                    <img :src="cropImg" class="cropped-img"/>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="cropImg" color="primary" text @click="saveImage()">Save</v-btn>
                    <v-btn color="primary" text @click="cropImage()">Crop</v-btn>
                    <v-btn color="primary" text @click="rotate()">Rotate</v-btn>
                    <v-btn color="link" text @click="modal = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Modal Box -->

    </div>

</template>

<script>
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';

    export default {
        data() {
            return {
                modal: null,
                img_src: '',
                cropImg: null,
                current_image: null,
                autoCropArea: 0.8,
                loading: false,
                passing: null,
                show_preview: false,
            }
        },

        props: ['image', 'error', 'imgWidth', 'imgHeight'],

        computed: {
            my_image() {
                return this.image != null && this.image !== '' ? this.image : false;
            },
        },

        components: {
            VueCropper,
        },

        methods: {
            prepareImage(e) {
                const file = e.target.files[0];
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                        let image = new Image();
                        image.src = event.target.result;

                        let self = this;
                        image.onload = function () {
                            const widthOrHeight = this.width >= this.height ? this.height : this.width;
                            const horizontalOrVertical = widthOrHeight === this.width ? self.imgWidth : self.imgHeight;
                            self.autoCropArea = Number((horizontalOrVertical / widthOrHeight).toFixed(16));
                            self.passing = e;
                        };
                    };

                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            setImage(e) {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async (event) => {
                    await this.setModal(true);
                    await this.$refs.cropper.replace(event.target.result);
                };
            },
            cropImage() {
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
                this.show_preview = true;
            },
            rotate() {
                this.$refs.cropper.rotate(90);
            },
            async saveImage() {
                this.current_image = this.cropImg;

                this.$emit('uploadImage', {
                    file: dataURLtoFile(this.cropImg, 'image.jpg'),
                });

                this.setModal(false);
                this.show_preview = false;
            },
            async setModal(modal){
                this.modal = modal;
                return 'done';
            },
            clearImage(){
                this.current_image = null;
                this.$emit('removeImage');
            },
        },

        watch: {
            passing(e) {
                this.setImage(e);
            },
        },
    }
</script>

<style scoped>
    .uploader-wrap {
        display: inline-block;
        width: 150px;
        height: 115px;
        opacity: 0.5;
        padding: 5px;
        cursor: pointer;
        border: 1px dashed gray;
        position: relative;
        text-align: center;
        overflow: hidden;
    }

    #file {
        display: none;
    }

    .uploader-icon-wrap {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .uploader-wrap:hover .uploader-icon {
        opacity: 0.8;
    }

    .uploader-icon {
        font-size: 55px !important;
        opacity: 0.2;
    }

    .cropped-img {
        width: 100%;
    }

    .cropped-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .remove-icon{
        position: absolute;
        top: 5px;
        left: 5px;
        color: red;
        cursor: pointer;
        z-index: 2;
    }
</style>
