export default {
    banners: [],
    banner: {
        remove_image: false,
    },
    types: [
        '250x250',
        '1110x240'
    ]
}
