export const index = (state, discounts) => {
    state.discounts = discounts;
};

export const show = (state, discount) => {
    state.discount = discount;
};

export const clear = state => {
    state.discount = {
        parent: 0,
    };
};

export const clearAll = state => {
    state.discounts = [];
};
