module.exports = {
    theme_light_colors: {
        primary: '#805372', // nav
        secondary: '#F9A31A',
        accent: '#CFD8DC',
        error: '#b71c1c',
        warning: '#3f4a59',
        borde: '#d6d6d6'
    },
    theme_dark_colors: {
        primary: '#1a749e',
        secondary: '#E0E0E0',
        accent: '#212121',
        error: '#EF5350',
        warning: '#424242',
        borde: '#cef442'
    },
};

// module.exports = {
//     theme_light_colors: {
//         primary: '#607D8B',
//         secondary: '#455A64',
//         accent: '#8c9eff',
//         error: '#b71c1c'
//     },
//     theme_dark_colors: {
//         primary: '#212121',
//         secondary: '#424242',
//         accent: '#8c9eff',
//         error: '#EF5350'
//     },
// }

// #424242