export const index = (state, tags) => {
    state.tags = tags;
};

export const show = (state, tag) => {
    state.tag = tag;
};

export const clear = state => {
    state.tag = {};
};

export const clearAll = state => {
    state.tag = [];
};
