<template>
    <form>
        <v-text-field
            v-model="school_class.name"
            :error-messages="error_messages.name"
            :label="checkTranslation(user.profile.translations.name, 'Name')"
            required
        ></v-text-field>

        <v-checkbox
            :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
            v-model="school_class.is_visible"
            color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{ checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{ checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            school_class() {
                return this.$store.getters['school_class/getSchoolClass'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear(){
                this.$store.dispatch('school_class/clear');
            },
        },
    }
</script>
