<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form v-if="book">

                        <v-tabs
                                v-model="active"
                                background-color="primary"
                                slider-color="secondary"
                                class="pa-4"
                        >
                            <v-tab ripple>{{ checkTranslation(user.profile.translations.edit, 'Edit') }}</v-tab>
                            <v-tab ripple>{{ checkTranslation(user.profile.translations.gallery, 'Gallery') }}</v-tab>

                            <v-tab-item>
                                <v-card flat>

                                    <form-fields @submitForm="submit($event)"></form-fields>

                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card>

                                    <drop-zone :book="book"></drop-zone>

                                    <gallery></gallery>

                                </v-card>
                            </v-tab-item>

                        </v-tabs>


                    </form>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('book-create')">
                <v-btn color="primary" dark fab fixed bottom right to="/books/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>

        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import DropZone from '../helper/DropZone.vue';
    import Gallery from './Gallery.vue';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data() {
            return {
                fillable: fields,
                tab: null,
                active: 0,
            }
        },

        mixins: [CheckGuard,],

        components: {
            DropZone,
            Gallery,
            FormFields,
        },

        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            book() {
                return this.$store.getters['book/getBook'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.books, 'Books'),
                        disabled: false,
                        to: '/books',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted() {
            this.clear();
            await this.getCategories();
            await this.getStatuses();
            await this.getAuthors();
            await this.getBook();
            await this.getTags();
        },

        methods: {
            async getCategories() {
                await this.$store.dispatch('category/index', {
                    lists: 1,
                    sortBy: ['order'],
                    sortDesc: [0],
                    parent: 0,
                    filterOptions: 1
                });
            },
            async getStatuses() {
                await this.$store.dispatch('status/index', {
                    lists: 1,
                    sortBy: ['order'],
                    sortDesc: [0],
                    parent: 0,
                    filterOptions: 1
                });
            },
            async getAuthors() {
                await this.$store.dispatch('author/index', {
                    sortBy: ['id'],
                    sortDesc: [1],
                    lists: true,
                    parent: 0,
                });
            },
            async getTags() {
                await this.$store.dispatch('tag/index', {
                    sortBy: ['id'],
                    sortDesc: [1],
                    lists: true,
                    parent: 0,
                });
            },
            async getBook() {
                await this.$store.dispatch('book/show', this.$route.params.slug);
            },
            clear() {
                this.$store.dispatch('book/clear');
            },
            async submit() {
                this.prepareSubmit();
                let data = fillForm(this.fillable, this.book, 'PUT');
                await this.$store.dispatch('book/update', [data, this.$route.params.slug],);
            },
            prepareSubmit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                this.book.author_ids = this.book.authors !== undefined ? this.book.authors.map(book => Number.isInteger(book) ? book : book.id) : [];
                this.book.tag_ids = this.book.tags !== undefined ? this.book.tags.map(book => Number.isInteger(book) ? book : book.id) : [];
            },
        },
    }
</script>
