export const index = (state, links) => {
    state.links = links;
};

export const show = (state, link) => {
    state.link = link;
};

export const clear = state => {
    state.link = {
        order: 1,
        parent: 0,
        level: 1
    };
};

export const clearAll = state => {
    state.links = [];
};