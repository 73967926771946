<template>
    <form v-if="tag">
        <v-text-field
                v-model="tag.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>
        <textarea-ckeditor
                :label="checkTranslation(user.profile.translations.text, 'Text')"
                :value="tag.text"
                :required="false"
                @changeValue="tag.text = $event"
        ></textarea-ckeditor>
        <v-text-field
            v-model="tag.order"
            :error-messages="error_messages.order"
            :label="checkTranslation(user.profile.translations.order, 'Order')"
            required
        ></v-text-field>
        <v-text-field
            v-model="tag.link"
            :error-messages="error_messages.link"
            :label="checkTranslation(user.profile.translations.url, 'Url')"
            required
        ></v-text-field>
        <v-select
            v-if="templateTypes"
            v-model="tag.template_type"
            :items="templateTypes"
            :label="checkTranslation(user.profile.translations.template_type, 'Template type')"
            :error-messages="error_messages.template_type"
            clearable
            item-text="name"
            item-value="value"
        ></v-select>
        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="tag.is_visible"
                color="primary"
        ></v-checkbox>
        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>

        <v-btn @click="clear()" color="secondary" tile>{{checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            tag() {
                return this.$store.getters['tag/getTag'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
            templateTypes() {
                return this.$store.getters['tag/getTemplateTypes'];
            },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear() {
                this.$store.dispatch('tag/clear');
            },
        },
    }
</script>
