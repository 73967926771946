export const index = (state, discount_types) => {
    state.discount_types = discount_types;
};

export const show = (state, discount_type) => {
    state.discount_type = discount_type;
};

export const clear = state => {
    state.discount_type = {
        parent: 0,
    };
};

export const clearAll = state => {
    state.discount_types = [];
};
