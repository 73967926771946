export const index = (state, newsletters) => {
    state.newsletters = newsletters;
};

export const show = (state, newsletter) => {
    state.newsletter = newsletter;
};

export const clear = state => {
    state.newsletter = {};
};

export const clearAll = state => {
    state.newsletters = [];
};