<template>
    <form>
        <v-text-field
            v-model="digital_book.title"
            :error-messages="error_messages.title"
            :label="checkTranslation(user.profile.translations.digital_book_name, 'Title')"
            required
        ></v-text-field>

        <v-select
            v-if="schoolClasses"
            v-model="digital_book.school_class_id"
            :items="schoolClasses"
            :label="checkTranslation(user.profile.translations.school_class, 'School Class')"
            :error-messages="error_messages.school_class_id"
            clearable
            item-text="name"
            item-value="id"
        ></v-select>

        <textarea-ckeditor
            :label="checkTranslation(user.profile.translations.content, 'Content')"
            :value="digital_book.description"
            :required="false"
            @changeValue="digital_book.description = $event"
        ></textarea-ckeditor>

        <upload-image-cropper
            :imgWidth="287"
            :imgHeight="406"
            :model="digital_book"
            :image="digital_book.image"
            @uploadImage="setImage($event)"
            @removeImage="removeImage()"
        ></upload-image-cropper>

        <v-checkbox
            :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
            v-model="digital_book.is_visible"
            color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{ checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{ checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            digital_book() {
                return this.$store.getters['digital_book/getDigitalBook'];
            },
            schoolClasses() {
                return this.$store.getters['school_class/getSchoolClasses'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },
        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear(){
                this.$store.dispatch('digital_book/clear');
            },
            setImage(image) {
                this.digital_book.image = image.file;
            },
            removeImage(image) {
                this.digital_book.image = null;
                this.digital_book.remove_image = true;
            },
        },
    }
</script>

