<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4 mb-4" v-if="cart.address">
                    <h4>{{ checkTranslation(user.profile.translations.cart_informations, 'Cart informations') }}</h4>
                    <ul class="list">
                        <li><b>{{ checkTranslation(user.profile.translations.order_id, 'Order ID') }}</b>: {{ cart.order_number }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.customer, 'Customer') }}</b>: {{ cart.address.name }} {{ cart.address.lastname }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.email, 'Email') }}</b>: {{ cart.address.email }} </li>
                        <li><b>{{ checkTranslation(user.profile.translations.phone, 'Phone') }}</b>: {{ cart.address.phone }} </li>
                        <li><b>{{ checkTranslation(user.profile.translations.address, 'Street') }}</b>: {{ cart.address.street }} </li>
                        <li><b>{{ checkTranslation(user.profile.translations.zipcode_town, 'City') }}</b>: {{ cart.address.city }} </li>
                        <li><b>{{ checkTranslation(user.profile.translations.country, 'Country') }}</b>: {{ cart.address.country }} </li>
                        <li><b>{{ checkTranslation(user.profile.translations.shipping, 'Shipping') }}</b>: {{ cart.shipping }} RSD</li>
                        <li><b>{{ checkTranslation(user.profile.translations.total_without_shipping, 'Total Without Shipping') }}</b>: {{ cart.total }} RSD</li>
                        <li><b>{{ checkTranslation(user.profile.translations.total, 'Total') }}</b>: {{ cart.total + cart.shipping }} RSD</li>
                        <li><b>{{ checkTranslation(user.profile.translations.created_at, 'Created At') }}</b>: {{ cart.created_at }}</li>
                        <li><b>{{ checkTranslation(user.profile.translations.comment, 'Comment') }}</b>: {{ cart.comment }}</li>
                    </ul>
                </v-card>

                <v-card class="pa-4" v-if="cart">

                    <v-data-table
                            :headers="headers"
                            :items="cart.items"
                            :hide-default-footer="true"
                            class="elevation-1"
                            :show-select="showSelect"
                    >

                        <template v-slot:item.price="{ item }">
                            {{ item.count }} x {{ item.price }} RSD
                        </template>

                    </v-data-table>

                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';

    export default {

        data() {
            return {
                headers: [
                    {text: 'ID', align: 'left', value: 'id'},
                    {text: 'NAME', align: 'left', value: 'details.title'},
                    {text: 'CODE', align: 'left', value: 'details.code'},
                    {text: 'PRICE', align: 'left', value: 'price'},
                ],
                model: 'ShoppingCart',
                showSelect: false,
            };
        },

        mixins: [CheckGuard,],

        computed: {
            cart() {
                return this.$store.getters['cart/getCart'];
            },
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.carts, 'Carts'),
                        disabled: false,
                        to: '/carts',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        async mounted(){
            this.clear();
            this.translate();
            await this.getCart();
        },

        methods: {
            async getCart(){
                await this.$store.dispatch('cart/show', this.$route.params.id);
            },
            clear(){
                this.$store.dispatch('cart/clear');
            },
            translate() {
                this.headers[1].text = this.user.profile.translations.title.toUpperCase();
                this.headers[2].text = this.user.profile.translations.code.toUpperCase();
                this.headers[3].text = this.user.profile.translations.price.toUpperCase();
            },
        },
    }
</script>

<style scoped>
    .list{
        list-style: none;
        padding-left: 0;
    }

    .list > li {
        height: 25px;
    }
</style>
