var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"mb-4"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_vm._v(" "),_c('v-card',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.total_items,"loading":_vm.loading,"items-per-page":50,"footer-props":{
                          itemsPerPageOptions: [50,25,10],
                          itemsPerPageText: _vm.checkTranslation(_vm.user.profile.translations.rows_per_page, 'Rows per page'),
                        },"show-select":_vm.showSelect},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.id))]),_vm._v(" "),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.title))]),_vm._v(" "),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.locale))]),_vm._v(" "),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.is_visible? 'Yes' : 'No'))]),_vm._v(" "),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.created_at))])]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(_vm.checkGuard('language-edit'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","icon":"","color":"primary","to":'/dictionaries/' + item.id + '/edit'}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.checkTranslation(_vm.user.profile.translations.edit, 'Edit')))])]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }