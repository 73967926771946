export const index = (state, posts) => {
    state.posts = posts;
};

export const show = (state, post) => {
    state.post = post;
};

export const clear = state => {
    state.post = {
        remove_image: false,
    };
};

export const clearAll = state => {
    state.posts = [];
};