<template>
    <div class="mb-5">
        <label>{{ label }} <span v-if="required">*</span></label>
        <ckeditor
            v-model="content"
            :config="config"
        ></ckeditor>
        <small class="form-text text-muted" v-if="error != null && error">{{ error[0] }}</small>
    </div>
</template>

<script>
    import Ckeditor from 'vue-ckeditor2';

    export default {
        data() {
            return {
                config: {
                    filebrowserUploadUrl: '/api/upload/image',
                    filebrowserUploadMethod: 'form',
                    toolbar: [
                        { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
                        { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                        { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
                        '/',
                        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
                        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                        { name: 'insert', items: ['Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe', 'Image' ] },
                        '/',
                        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                        { name: 'about', items: [ 'About' ] },
                    ],
                    height: 300,
                    filebrowserBrowseUrl: 'file-manager',
                    // skin: 'moono-lisa',
                },
            }
        },

        props: ['label', 'value', 'error', 'required'],

        components: {Ckeditor},

        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            content:{
                get(){
                    return this.value || null;
                },
                set(value){
                    this.$emit('changeValue', value);
                }
            },
        },
    }
</script>

<style scoped>
    span {
        color: red;
    }

    label {
        color: rgba(0, 0, 0, .54);
    }
</style>
