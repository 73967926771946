<template>
    <form v-if="box">
        <v-text-field
                v-model="box.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required
        ></v-text-field>

        <upload-image-cropper
                :imgWidth="630"
                :imgHeight="400"
                :model="box"
                :image="box.image"
                @uploadImage="setImage($event)"
                @removeImage="removeImage()"
        ></upload-image-cropper>

        <v-text-field
                v-model="box.content"
                :error-messages="error_messages.content"
                :label="checkTranslation(user.profile.translations.content, 'Content')"
                required
        ></v-text-field>

        <v-text-field
                v-model="box.url"
                :error-messages="error_messages.url"
                :label="checkTranslation(user.profile.translations.url, 'Url')"
                required
        ></v-text-field>

        <v-text-field
                v-model="box.button"
                :error-messages="error_messages.button"
                :label="checkTranslation(user.profile.translations.button, 'Button')"
                required
        ></v-text-field>

        <v-text-field
                v-model="box.order"
                :error-messages="error_messages.order"
                :label="checkTranslation(user.profile.translations.order, 'Order')"
                required
        ></v-text-field>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="box.is_visible"
                color="primary"
        ></v-checkbox>

        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">{{ checkTranslation(user.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{ checkTranslation(user.profile.translations.clear, 'Clear') }}</v-btn>
    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            box() {
                return this.$store.getters['box/getBox'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            setImage(image) {
                this.box.image = image.file;
            },
            removeImage(image) {
                this.box.image = null;
                this.box.remove_image = true;
            },
            clear(){
                this.$store.dispatch('box/clear');
            },
        },
    }
</script>
