export default function vuetifyPagination(pagination) {
    const { sortBy, sortDesc, page, itemsPerPage, isVisible, lists, parent, address, cart, city, name, title, category_id, category_news_id, menu_id, books, pib } = pagination;

    let url = '';
    url += sortBy.length > 0 ? sortBy[0] + '=1&' : 'id=1&';
    url += page ? 'page=' + page + '&' : '';
    url += sortDesc.length > 0 ? 'DESC=' + (sortDesc[0] ? sortDesc[0] : 0) + '&' : 'DESC=1&';
    url += itemsPerPage ? 'paginate=' + itemsPerPage + '&' : '';
    url += lists ? 'lists=' + lists + '&' : '';
    url += isVisible !== undefined ? 'is_visible=' + isVisible + '&' : '';
    url += parent !== undefined ? 'search_parent=' + parent + '&' : '';
    url += address !== undefined ? 'search_address=' + address + '&' : '';
    url += cart !== undefined ? 'search_cart=' + cart + '&' : '';
    url += city !== undefined ? 'search_city=' + city + '&' : '';
    url += name !== undefined ? 'search_name=' + name + '&' : '';
    url += title !== undefined ? 'search_title=' + title + '&' : '';
    url += category_id !== undefined ? 'search_category_id=' + category_id + '&' : '';
    url += category_news_id !== undefined ? 'search_category_news_id=' + category_news_id + '&' : '';
    url += menu_id !== undefined ? 'search_menu_id=' + menu_id + '&' : '';
    url += books !== undefined ? 'search_books=' + books + '&' : '';
    url += pib !== undefined ? 'search_pib=' + pib + '&' : '';

    return url;
}
