<template>
    <form>
        <v-text-field
                v-model="language.title"
                :error-messages="error_messages.title"
                :label="checkTranslation(user.profile.translations.title, 'Title')"
                required>
        </v-text-field>

        <v-text-field
                v-model="language.locale"
                :error-messages="error_messages.locale"
                :label="checkTranslation(user.profile.translations.locale, 'Locale')"
                required>
        </v-text-field>

        <v-text-field
                v-model="language.order"
                :error-messages="error_messages.order"
                :label="checkTranslation(user.profile.translations.order, 'Order')">
        </v-text-field>

        <v-checkbox
                :label="checkTranslation(user.profile.translations.is_visible, 'Is visible')"
                v-model="language.is_visible"
                color="primary">
        </v-checkbox>

        <v-btn @click="submit()" color="primary" tile class="mr-3">{{ checkTranslation(user.profile.translations.submit.toUpperCase(), 'SUBMIT') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{ checkTranslation(user.profile.translations.clear.toUpperCase(), 'CLEAR') }}</v-btn>
    </form>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            language() {
                return this.$store.getters['language/getLanguage'];
            },
            error_messages() {
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
            clear(){
                this.$store.dispatch('language/clear');
            },
        },
    }
</script>