import swal from 'sweetalert2';

/**
 * method used to get dictionary words item from Backend and store in Vuex
 *
 * @param {object} context
 * @param {int} id - language_id
 */
export const show = async (context, id) => {
    await axios.get('api/dictionaries/' + id)
        .then(res => {
            let dictionary = res.data.data;
            let words = [];
            let keys = [];
            for(let i=0;i<dictionary.length;i++){
                keys.push(dictionary[i].key);
                words.push(dictionary[i].value);
            }
            context.commit('words', words);
            context.commit('keys', keys);
        })
        .catch(e => {
            console.log(e);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to update language item to the Backend and store in Vuex
 *
 * @param {object} context
 * @param {array} callback - (array[0] - data words, array[1] - language_id)
 */
export const update = async (context, callback) => {
    let user = context.rootGetters['user/getUserProfile'];

    await axios.post('api/dictionaries/' + callback[1], callback[0])
        .then( res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: checkTranslation(user.profile.translations.success, 'Success'),
                showConfirmButton: false,
                timer: 1500
            });
            context.dispatch('auth/storeUser', [], {root:true});
            context.dispatch('common/changeErrorMessages', [], {root:true});
        })
        .catch( e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to clear languages properties
 *
 * @param {object} context
 */
export const clearAll = async context => {
    await context.commit('clearAll');
};
