<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>

                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>

            <v-fab-transition v-if="checkGuard('language-create')">

                <v-btn :color="'primary'" dark fab fixed bottom right to="/languages/create">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

            </v-fab-transition>
        </v-layout>
    </v-container>
</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data () {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            language(){
                return this.$store.getters['language/getLanguage'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.languages, 'Languages'),
                        disabled: false,
                        to: '/languages',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.edit, 'Edit'),
                        disabled: true,
                    },
                ];
            },
        },

        mixins: [CheckGuard,],

        mounted(){
            this.clear();
            this.getLanguage();
        },

        methods: {
            async getLanguage(){
                await this.$store.dispatch('language/show', this.$route.params.id);
            },
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                let data = fillForm(this.fillable, this.language, 'PUT');
                await this.$store.dispatch('language/update', [data, this.$route.params.id]);
            },
            clear(){
                this.$store.dispatch('language/clear');
            },
            setImage(image){
                this.language.image = image.file;
            },
        },
    }
</script>
