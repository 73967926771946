<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>
                <v-card class="pa-4">
                    <form @submitForm="submit($event)" ref="my_input">
                        <vue-repeater
                            v-model="fields"
                        ></vue-repeater>
                        <v-btn @click.prevent="submit()" color="primary" tile class="mr-3">Submit</v-btn>
                    </form>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import FormFields from './FormFields.vue';
    // import {getSchool} from "../../store/modules/school/getters";

    export default {
        data () {
            return {
                fields: [
                    {
                        name: FormFields,
                        value: {
                        }
                    }
                ],
                codeCollection: {
                    school_id: '',
                    digital_book_id: '',
                    code_count: ''
                },
                collectionArr: {
                    codes: []
                }
            }
        },
        components: {
            FormFields,
        },
        computed: {
            breadcrumbs() {
                return [
                    {
                        text: 'Home',
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Digital Books',
                        disabled: false,
                        to: '/digital-books',
                        exact: true,
                    },
                    {
                        text: 'Create',
                        disabled: true,
                    },
                ];
            },
        },
        methods: {
            async getSchools(){
                await this.$store.dispatch('school/index', {lists: 1, sortBy: ['id'], sortDesc: [0]});
            },
            async getDigitalBooks(){
                await this.$store.dispatch('digital_book/index', {lists: 1, sortBy: ['id'], sortDesc: [0]});
            },
            async submit(data) {
                this.fields.forEach((data, index) => {
                    this.codeCollection.digital_book_id = data.value.digital_book_id;
                    this.codeCollection.code_count = data.value.code_count;
                    this.codeCollection.school_id = data.value.school_id;

                    this.collectionArr.codes.push(this.codeCollection);
                });

                this.$store.dispatch('common/changeErrorMessages', [], {root: true});
                await this.$store.dispatch('digital_book_code/store', this.collectionArr);
            },
        },
        mounted() {
            this.getSchools();
            this.getDigitalBooks();
        },

    }
</script>

