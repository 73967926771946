<template>

    <form>

        <v-text-field
                v-model="user.username"
                :error-messages="error_messages.username"
                :label="checkTranslation(userProfile.profile.translations.username, 'Username')"
                required
        ></v-text-field>

        <v-text-field
                v-model="user.email"
                type="text"
                :error-messages="error_messages.email"
                label="Email"
                required
        ></v-text-field>

        <v-text-field
                v-model="user.password"
                type="password"
                :error-messages="error_messages.password"
                :label="checkTranslation(userProfile.profile.translations.password, 'Password')"
                required
        ></v-text-field>

        <v-text-field
                v-model="user.password_confirmation"
                type="password"
                :error-messages="error_messages.password_confirmation"
                :label="checkTranslation(userProfile.profile.translations.password_confirmation, 'Password confirmation')"
                required
        ></v-text-field>

        <v-select
                v-if="languages && checkGuard('language-create')"
                :items="languages"
                :label="checkTranslation(userProfile.profile.translations.language, 'Language')"
                v-model="user.language_id"
                :error-messages="error_messages.language_id"
                item-text="title"
                item-value="id"
        ></v-select>

        <v-combobox
                v-if="roles"
                v-model="user.roles"
                :items="roles"
                :label="checkTranslation(userProfile.profile.translations.roles, 'Roles')"
                :error-messages="error_messages.role_ids"
                item-text="name"
                item-value="id"
                multiple
                chips
        ></v-combobox>

        <v-checkbox
                :label="checkTranslation(userProfile.profile.translations.verify_email, 'Verify email')"
                v-model="user.email_verified_at"
                color="primary"
        ></v-checkbox>

        <v-checkbox
                :label="checkTranslation(userProfile.profile.translations.block, 'Block')"
                v-model="user.block"
                color="primary"
        ></v-checkbox>

        <v-btn @click="submit()" color="primary" tile class="mr-3">{{ checkTranslation(userProfile.profile.translations.submit, 'Submit') }}</v-btn>
        <v-btn @click="clear()" color="secondary" tile>{{ checkTranslation(userProfile.profile.translations.clear, 'Clear') }}</v-btn>

    </form>

</template>

<script>
    import CheckGuard from '../../mixins/CheckGuard.js';

    export default {

        mixins: [CheckGuard,],

        computed: {
            userProfile(){
                return this.$store.getters['user/getUserProfile'];
            },
            user(){
                return this.$store.getters['user/getUser'];
            },
            roles(){
                return this.$store.getters['role/getRoles'];
            },
            languages(){
                return this.$store.getters['language/getLanguages'];
            },
            error_messages(){
                return this.$store.getters['common/getErrorMessages'];
            },
        },

        methods: {
            submit(){
                this.$emit('submitForm');
            },
        },
    }
</script>