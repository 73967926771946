export const index = (state, pages) => {
    state.pages = pages;
};

export const show = (state, page) => {
    state.page = page;
};

export const clear = state => {
    state.page = {

    };
};

export const clearAll = state => {
    state.pages = [];
};
