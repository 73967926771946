import swal from 'sweetalert2';

/**
 * method used to update profile password item to the Backend
 *
 * @param {object} context
 * @param {array} callback[0] - profile item | callback[1] - user_id
 */
export const passwordUpdate = async (context, callback) => {
    await axios.post(`api/password/${callback[1]}`, callback[0])
        .then(res => {
            swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Success',
                showConfirmButton: false,
                timer: 1500
            });
            context.dispatch('common/changeErrorMessages', [], {root:true});
        }).catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to update profile item to the Backend
 *
 * @param {object} context
 * @param {array} callback[0] - profile item | callback[1] - user_id
 */
export const profileUpdate = async (context, callback) => {
    await axios.post(`api/profile/${callback[1]}?cms=1`, callback[0])
        .then(res => {
            context.commit('user/show_profile', res.data.data, {root:true});
            swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Success',
                showConfirmButton: false,
                timer: 1500
            });
            context.dispatch('common/changeErrorMessages', [], {root:true});
        }).catch(e => {
            console.log(e.response.data);
            context.dispatch('common/changeErrorMessages', e.response.data.errors, {root:true});
        });
};

/**
 * method used to clear error messages from Vuex
 *
 * @param {object} context
 */
export const clear = context => {
    context.dispatch('common/changeErrorMessages', [], {root:true});
};
