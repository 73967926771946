export const index = (state, images) => {
    state.images = images;
};

export const show = (state, image) => {
    state.image = image;
};

export const clear = state => {
    state.image = {};
};

export const clearAll = state => {
    state.images = [];
};