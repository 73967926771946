<template>

    <v-container fluid fill-height>
        <v-layout>

            <v-flex>

                <v-card class="mb-4">

                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>

                </v-card>

                <v-card class="pa-4">

                    <v-data-table
                            v-if="items"
                            :headers="headers"
                            :items="items"
                            hide-default-footer
                            :loading="loading"
                            class="elevation-1"
                            group-by="permission_group_name"
                            :items-per-page="200"
                    >
                        <template slot="items" slot-scope="props">
                            <td class="text-right">
                                <v-checkbox
                                        :label="permission.name"
                                        :value="permission.id"
                                        v-model="permission_ids"
                                        color="primary"
                                ></v-checkbox>
                            </td>
                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-checkbox
                                    :label="item.guard_name"
                                    :value="item.id"
                                    v-model="permission_ids"
                                    color="primary"
                            ></v-checkbox>

                        </template>

                    </v-data-table>

                    <v-btn @click="submit()" tile color="primary" class="mt-4">submit</v-btn>

                </v-card>

            </v-flex>

        </v-layout>
    </v-container>

</template>

<script>
    export default {
        data() {
            return {
                pagination: {},
                headers: [
                    {text: 'PERMISSION GROUP NAME', align: 'left', sortable: false, value: 'permission_group_name'},
                    {text: 'ACTION', align: 'left', sortable: false, value: 'action'},
                ],
            }
        },

        computed: {
            user() {
                return this.$store.getters['user/getUserProfile'];
            },
            role() {
                return this.$store.getters['role/getRole'];
            },
            permission_ids: {
                get() {
                    return this.$store.getters['permission/getPermissionIds'];
                },
                async set(ids) {
                    return await this.$store.commit('permission/permission_ids', ids);
                },
            },
            items() {
                return this.$store.getters['permission/getPermissions'];
            },
            loading() {
                return this.$store.getters['common/getLoading'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.roles, 'Roles'),
                        disabled: false,
                        to: '/roles',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.permissions, 'Permissions') + ' for ' + this.role.name,
                        disabled: true,
                    },
                ];
            },
        },

        async mounted() {
            await this.getPermissions();
            await this.getPermissionIds();
            await this.getRole();
        },

        methods: {
            async getPermissions() {
                await this.$store.dispatch('permission/index', {
                    page: 1,
                    itemsPerPage: 50,
                    sortBy: "id",
                    sortDesc: [1],
                    totalItems: 0,
                    lists: true,
                });
            },
            async getPermissionIds() {
                await this.$store.dispatch('permission/showPermissionIds', this.$route.params.id);
            },
            async getRole(){
                await this.$store.dispatch('role/show', this.$route.params.id);
            },
            async submit() {
                await this.$store.dispatch('permission/storePermissionIds', this.$route.params.id);
            },
        },
    }
</script>
