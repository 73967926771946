export const index = (state, statuses) => {
    state.statuses = statuses;
};

export const show = (state, status) => {
    state.status = status;
};

export const clear = state => {
    state.status = {};
};

export const clearAll = state => {
    state.statuses = [];
};