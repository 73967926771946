<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-card class="mb-4">
                    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
                </v-card>

                <v-card class="pa-4">

                    <form-fields @submitForm="submit($event)"></form-fields>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import FormFields from './FormFields.vue';
    import fields from './fields.json';

    export default {
        data () {
            return {
                fillable: fields,
            }
        },

        components: {
            FormFields,
        },

        computed: {
            user(){
                return this.$store.getters['user/getUserProfile'];
            },
            permission(){
                return this.$store.getters['permission/getPermission'];
            },
            breadcrumbs() {
                return [
                    {
                        text: this.checkTranslation(this.user.profile.translations.home, 'Home'),
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.permissions, 'Permissions'),
                        disabled: false,
                        to: '/permissions',
                        exact: true,
                    },
                    {
                        text: this.checkTranslation(this.user.profile.translations.create, 'Create'),
                        disabled: true,
                    },
                ];
            },
        },

        mounted() {
            this.clear();
            this.getPermissionGroups();
        },

        methods: {
            async submit(){
                this.$store.dispatch('common/changeErrorMessages', [], {root:true});
                this.permission.locale = this.user.profile.language.locale;
                let data = fillForm(this.fillable, this.permission);
                await this.$store.dispatch('permission/store', data);
            },
            clear(){
                this.$store.dispatch('permission/clear');
            },
            async getPermissionGroups() {
                await this.$store.dispatch('permission_group/index', {
                    page: 1,
                    itemsPerPage: 50,
                    sortBy: "id",
                    sortDesc: [1],
                    totalItems: 0,
                    lists: true,
                });
            },
        },
    }
</script>