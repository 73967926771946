export const index = (state, boxes) => {
    state.boxes = boxes;
};

export const show = (state, box) => {
    state.box = box;
};

export const clear = state => {
    state.box = {
        remove_image: false,
    };
};

export const clearAll = state => {
    state.boxes = [];
};
