<template>
    <div>
        <iframe :src="domain + 'file-manager'" frameborder="0"></iframe>
    </div>
</template>

<script>
    export default {

        data(){
            return {
                domain: domain,
            };
        },
    }
</script>

<style scoped>
    iframe{
        display: block;
        border: none;
        height: 100vh;
        width: 100vw;
    }
</style>
